<template>
    <div class="frame equipments">
        <h1>Список обородования</h1>
        <div class="btn-add">
            <button-text class="add-equipment" :msg="'Добавить оборудование'" :path="'equipments/add'"></button-text>
        </div>

        <div v-if="getEquipments" class="equipments__group" v-for="group in getEquipments" :key="getEquipments">
            <div class="frame-title">
                <h3>{{ group.group_name }}</h3>
                <button-text v-if="group.hidden" :msg="'Развернуть'" :path="''" @touch="toggleSpisok(group)"></button-text>
                <button-text v-else :msg="'Свернуть'" :path="''" @touch="toggleSpisok(group)"></button-text>
            </div>
            <div v-if="!group.hidden">
                <div v-if="group.equipments.length != 0" class="block equipment" v-for="equipment in group.equipments">
                    <div class="equipment__head">
                        <div class="equipment__head__h">
                            <div class="equipment__head__signals">
                                <point :isWork="equipment.work" :msg="'Оборудование в работе'" :msgRed="'Не работает'">
                                </point>
                                <point :isWork="!!equipment.device" :msg="`Датчик подключен`"
                                    :msgRed="'Датчик не подключен'"></point>
                            </div>
                            <time-update :date="equipment.date_last_update"></time-update>
                        </div>

                        <div class="equipment__head__center">
                            <h4>{{ equipment.name }}</h4>
                            <p>{{ equipment.serial_number }}</p>
                        </div>
                        <button-text class="btn-sel-1" :msg="'Перейти'"
                            :path="`/equipments/${equipment.id}`"></button-text>
                        <button-arrow class="btn-sel-2" :path="`/equipments/${equipment.id}`"></button-arrow>
                    </div>

                </div>
                <div v-else class="block">
                    <h4>Пусто</h4>
                </div>
            </div>
            <div v-else>
                <div class="line-hor sip"></div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonArrow from '@/components/utils/ButtonArrow.vue';
import ButtonText from '@/components/utils/ButtonText.vue';
import Point from '@/components/utils/Point.vue';
import TimeUpdate from '@/components/utils/TimeUpdate.vue';
import { parseDate } from '@/helpers/utils';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'EquipmentsView',

    data() {
        return {
            equipments: null,
        }
    },

    computed: {
        getEquipments() {
            return this.equipments
        }
    },

    components: {
        ButtonText,
        ButtonArrow,
        Point,
        TimeUpdate
    },

    mounted() {
        this.feathEquipments();
    },

    methods: {

        async feathEquipments() {
            await this.download_eq();
            this.equipments = this.get_eq()
            // console.log(this.equipments)
        },

        toggleSpisok(group) {
            group.hidden = !group.hidden;
            // this.hide_equip({'group_id': group.group_id, 'hidden': group.hidden})
        },

        parseDate(date) {
            return parseDate(date)
        },

        ...mapActions({
            download_eq: 'equipments/download_equipments',
            hide_equip: 'equipments/hide_equip'
        }),

        ...mapGetters({
            get_eq: 'equipments/get_equipments'
        })

    }
}
</script>


<style lang="scss">
.equipments {

    .btn-add {
        width: fit-content;
        margin: 10px auto 20px auto;
    }

    .sip {
        margin: 20px auto;
    }

    .equipment {

        &__body {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media (max-width: 650px) {
                flex-direction: column;
            }
        }

        &__head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            

            &__h {
                display: flex;
                align-items: center;
                grid-gap: 20px;
            }

            &__signals {
                display: flex;
                grid-gap: 10px;
            }

            &__center {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                h4 {
                    margin-bottom: 5px;
                }

                p {
                    margin: 0;
                    font-size: 14px;
                    color: rgb(121, 121, 121);
                }
            }

        }
    }
}
</style>