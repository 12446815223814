import { createRouter, createWebHistory } from 'vue-router'
import SingInVuew from '@/views/SingInVuew.vue'
import StartAuthView from '@/views/StartAuthView.vue'
import DeviceView from '@/views/Device/DeviceView.vue'
import RegistrationView from '@/views/RegistrationView.vue'
import CoorView from '@/views/Equipment/CoorView.vue'
import EquipmentsView from '@/views/Equipment/EquipmentsView.vue'
import store from '@/store';
import ProfileVeiw from '@/views/ProfileVeiw.vue'
import DevicesView from '@/views/Device/DevicesView.vue'
import GroupAddView from '@/views/Group/GroupAddView.vue'
import DevicesAddView from '@/views/Device/DevicesAddView.vue'
import EquipmentsAddView from '@/views/Equipment/EquipmentsAddView.vue'
import GroupView from '@/views/Group/GroupView.vue'
import EquipmentView from '@/views/Equipment/EquipmentView.vue'
import AgreementView from '@/views/AgreementView.vue'


const routes = [
  {
    path: '/',
    name: 'start',
    component: StartAuthView,
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/get_authToken']) next('/equipments')
        else next()
    }
  },
  {
    path: '/login',
    name: 'singin',
    component: SingInVuew,
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/get_authToken']) next('/equipments')
        else next()
    }
  },
  {
    path: '/registration',
    name: 'registration',
    component: RegistrationView,
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/get_authToken']) next('/equipments')
        else next()
    }
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: AgreementView,
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/get_authToken']) next('/equipments')
        else next()
    }
  },
  {
    path: '/equipments',
    name: 'equipments',
    component: EquipmentsView,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/get_authToken']) next('/')
        else next()
    }
  },
  {
    path: '/equipments/:equipmentId',
    name: 'equipment',
    component: EquipmentView,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/get_authToken']) next('/')
        else next()
    }
  },
  {
    path: '/equipments/add',
    name: 'addequipments',
    component: EquipmentsAddView,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/get_authToken']) next('/')
        else next()
    }
  },
  {
    path: '/equipments/:equipmentId/coord',
    name: 'coord',
    component: CoorView,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/get_authToken']) next('/')
        else next()
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileVeiw,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/get_authToken']) next('/')
        else next()
    }
  },
  {
    path: '/devices',
    name: 'devices',
    component: DevicesView,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/get_authToken']) next('/')
        else next()
    }
  },
  {
    path: '/devices/:deviceId',
    name: 'device',
    component: DeviceView,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/get_authToken']) next('/')
        else next()
    }
  },
  {
    path: '/devices/add',
    name: 'addDevices',
    component: DevicesAddView,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/get_authToken']) next('/')
        else next()
    }
  },
  {
    path: '/groups/add',
    name: 'addGroup',
    component: GroupAddView,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/get_authToken']) next('/')
        else next()
    }
  },
  {
    path: '/groups/:groupId',
    name: 'Group',
    component: GroupView,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/get_authToken']) next('/')
        else next()
    }
  },
  
  // {
  //   path: '/account',
  //   name: 'account',
  //   component: AccountView,
  //   beforeEnter: (to, from, next) => {
  //     if (!store.getters['auth/get_authToken']) next('/start')
  //       else next()
  //   }
  // },
  // {
  //   path: '/main',
  //   name: 'main',
  //   component: StartView,
  //   beforeEnter: (to, from, next) => {
  //     if (!store.getters['auth/get_authToken']) next('/start')
  //       else next()
  //   }
  // },
  // {
  //   path: '/main/:deviceId',
  //   name: 'device',
  //   component: DeviceView,
  //   beforeEnter: (to, from, next) => {
  //     if (!store.getters['auth/get_authToken']) next('/start')
  //       else next()
  //   }
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next) => {
//   console.log(isAuthenticated + " beforeEach")
//   next()
//   // if (to.path !== '/login' && !isAuthenticated) {
//   //   next('/login')
//   //   console.log(1 + " " + to.path)
//   // }else if (to.path == '/login' && isAuthenticated) {
//   //   next('/main')
//   // }
//   // else if (to.path !== '/' && !isAuthenticated){
//   //    next('/')
//   //    console.log(2)
//   // }else if(to.path == '/' && isAuthenticated) {
//   //   next('/main')
//   // }
//   // else {
//   //   next()
//   //   console.log(3)
//   // }
// })

// router.beforeEach((to, from, next) => {
//   if (to.name !== 'singin' && !isAuthenticated) next({ name: 'singin' })
//   else next()
// })

// router.beforeEach((to, from, next) => {
//   if (to.name !== 'singin' && !isAuthenticated) next({ name: 'singin' })
//   else next()
// })

export default router
