<template>
    <router-link class="btn-back" :to="''" v-on:Click="touch">
        < назад </router-link>
</template>

<script>

export default {
    name: 'ButtonBack',

    props: {
        path: String,
    },

    methods: {

        touch() {
            this.$router.go(-1)
            // this.$emit('touch')
        }
    }
}
</script>

<style lang="scss">
.btn-back {
    color: #8e8e8e;
    text-decoration: none;
    // margin-top: 0;
}

.btn-back:hover {
    color: #6b6b6b;
    text-decoration: none;
    // margin-top: 0;
}
</style>