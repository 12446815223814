import api from "@/helpers/api"
import Cookies from "js-cookie"

export const Coordinate = {
    state: () => ({
        coord: null
    }),
    getters: {
        get_coord(state) {
            return state.coord
            
        },
    },
    mutations: {
        set_coord(state, coord) {
            state.coord = coord
            // console.log(state.device)
        },
    },
    actions: {
        async download_coord({ commit, state }, data) {
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('authToken')
                    }
                }

                const res = await api.get('/equipments/coord?equipment_id=' + data.equipmentId + "&date_one=" + data.dateOne + "&date_two=" + data.dateTwo, config)
                // console.log(res.data)
                commit('set_coord', res.data)
            } catch (error) {
                if (error.response.status == 401) {
                    singOut();
                }
                // console.log(error);
            }
            
        },
    },

    namespaced: true
}