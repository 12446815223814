<template>
    <div class="frame devices">
        <h1>Список датчиков</h1>
        <div class="btn-add">
            <button-text :msg="'Добавить датчик'" :path="'devices/add'"></button-text>
        </div>

        <div v-if="getDevices" class="devices__group" v-for="group in getDevices" :key="getDevices">
            <div class="frame-title">
                <h3>{{ group.group_name }}</h3>
                <button-text v-if="group.hidden" :msg="'Развернуть'" :path="''"
                    @touch="toggleSpisok(group)"></button-text>
                <button-text v-else :msg="'Свернуть'" :path="''" @touch="toggleSpisok(group)"></button-text>
            </div>
            <div v-if="!group.hidden">
                <div v-if="group.devices.length != 0" class="block device" v-for="device in group.devices">
                    <div class="device__head">
                        <point :isWork="device.isWork"></point>
                        <div class="device__head__center">
                            <h4>{{ device.name }}</h4>
                            <p>{{ device.serial_number }}</p>
                        </div>
                        <button-text class="btn-sel-1" :msg="'Перейти'" :path="`/devices/${device.id}`"></button-text>
                        <button-arrow class="btn-sel-2" :path="`/devices/${device.id}`"></button-arrow>
                    </div>

                    <div class="device__body">
                        <div class="device__body__equipment" v-if="device.equipment">
                            <p class="device__body__equipment__title">{{ device.equipment.name }}</p>
                            <p>{{ device.equipment.serial_number }}</p>
                        </div>
                        <p v-else>Нет привязоного оборудования</p>

                        <p v-if="device.date_last_update">{{ parseDate(device.date_last_update) }}</p>
                        <p v-else>Нет последнего обновления</p>
                    </div>

                </div>
                <div v-else class="block">
                    <h4>Пусто</h4>
                </div>
            </div>
            <div v-else>
                <div class="line-hor sip"></div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ButtonText from '@/components/utils/ButtonText.vue';
import Point from '@/components/utils/Point.vue';
import ButtonArrow from '@/components/utils/ButtonArrow.vue';
import { parseDate } from '@/helpers/utils';

export default {
    name: 'DevicesView',

    data() {
        return {
            devices: null,
        }
    },

    computed: {
        getDevices() {
            return this.devices
        }
    },

    components: {
        ButtonText,
        ButtonArrow,
        Point
    },

    mounted() {
        this.feathDevices();
    },

    methods: {

        async feathDevices() {
            await this.download_dev();
            this.devices = this.get_dev()
            // console.log(this.devices)
        },

        toggleSpisok(group) {
            group.hidden = !group.hidden;
            // this.hide_equip({'group_id': group.group_id, 'hidden': group.hidden})
        },

        parseDate(date) {
            return parseDate(date)
        },

        ...mapActions({
            download_dev: 'devices/download_devices'
        }),

        ...mapGetters({
            get_dev: 'devices/get_devices'
        })

    }
}
</script>


<style lang="scss">
.devices {

    .btn-add {
        width: fit-content;
        margin: 10px auto 20px auto;
    }

    .sip {
        margin: 20px auto;
    }

    .device {

        &__body {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 650px) {
                flex-direction: column;
            }

            &__equipment {
                display: flex;
                grid-gap: 20px;

                &__title {
                    font-weight: 500;
                }
            }
        }



        &__head {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__center {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                h4 {
                    margin-bottom: 5px;
                }

                p {
                    font-size: 14px;
                    margin: 0;
                    color: rgb(121, 121, 121);
                }
            }

        }
    }
}
</style>