<template>
    <div class="frame agreement">
        <h1>Согласие на обработку персональных данных</h1>
        <p class="agreement__text">
            Предоставляя свои персональные данные, Пользователь даёт согласие на обработку, хранение и использование
            своих персональных данных на основании ФЗ Nº 152-Ф3 «О персональных данных» от 27.07.2006 г. в следующих
            целях: <br>

            • Регистрации Пользователя на сайте<br>
            • Осуществление клиентской поддержки<br>
            • Получения Пользователем информации о маркетинговых событиях<br>
            • Выполнение Продавцом обязательств перед Покупателем<br>
            • Проведения аудита и прочих внутренних исследований с целью повышения качества предоставляемых услуг.<br><br>

            Под персональными данными подразумевается любая информация личного характера, позволяющая установить
            личность Покупателя такая как:<br>
            • Фамилия, Имя, Отчество<br>
            • Дата рождения<br>
            • Контактный телефон<br>
            • Адрес электронной почты<br>
            • Почтовый адрес<br><br>

            Персональные данные Пользователь хранятся исключительно на электронных носителях и обрабатываются с
            использованием автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка
            персональных данных необходима в связи с исполнением требований законодательства.<br><br>

            Продавец обязуется не передавать полученные персональные данные третьим лицам, за исключением следующих
            случаев:<br><br>

            • По запросам уполномоченных органов государственной власти РФ только по основаниям и в порядке,
            установленным законодательством РФ<br>
            • Стратегическим партнерам, которые работают с Продавцом для предоставления продуктов и услуг, или тем из
            них, которые помогают Продавцу реализовывать продукты и услуги потребителям. Мы предоставляем третьим лицам
            минимальный объем персональных данных, необходимый только для оказания требуемой услуги или проведения
            необходимой транзакции.<br><br>

            Продавец оставляет за собой право вносить изменения в одностороннем порядке в настоящие правила, при
            условии, что изменения не противоречат действующему законодательству РФ. Изменения условий настоящих правил
            вступают в силу после их публикации на Сайте.
        </p>
        <div class="agreement__btn">
            <button-gray :msg="'Вернуться'" @touch="back"></button-gray>
        </div>
        
    </div>

</template>

<script>
import ButtonGray from '@/components/utils/ButtonGray.vue';

export default {
    name: 'AgreementView',

    data() {
        return {
        }
    },

    computed: {

    },

    components: {
        ButtonGray
    },

    mounted() {
    },

    methods: {

        back() {
            this.$router.back()
        }
    }
}
</script>


<style lang="scss">

.agreement {

    &__text {
        width: 80%;
        margin: 20px auto;
    }

    &__btn {
        display: flex;
        flex-direction: column;
        width: 30%;
        margin: auto;
        margin-bottom: 20px;
    }
}

</style>