<template>
    <router-link class="btn-red" :to="path" v-on:Click="touch">
        {{ msg }}
    </router-link>
</template>

<script>
export default {
    name: 'ButtonRed',
    props: {
        msg: String,
        path: {
            type: String,
            default: ''
        }
    },
    
    methods: {

        touch() {
            this.$emit('touch')
        }
    }
}
</script>

<style lang="scss">

.btn-red {
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    color: rgb(240, 240, 240);
    background-color: rgb(241, 78, 78);
    padding: 10px;
    
}

.btn-red:hover {
    color: rgb(243, 226, 226);
    background-color: rgb(240, 64, 64);
}

.btn-red:active {
    color: rgb(255, 240, 240);
    background-color: rgb(241, 28, 28);
}

</style>
