<template>
    <div v-if="getEquipment" class="frame equipment">
        <h1>{{ getEquipment.name }}</h1>

        <div class="line">
            <div class="block equipment__info">
                <h4>Информация</h4>

                <div class="equipment__info__raw">
                    <p class="title">Серийный номер</p>
                    <p>{{ equipment.serial_number }}</p>
                </div>

                <div class="line-hor"></div>

                <div class="equipment__info__raw">
                    <p class="title">Статус</p>
                    <p v-if="equipment.is_work">Работает</p>
                    <p v-else>На складе</p>
                </div>

                <div class="line-hor"></div>

                <div class="equipment__info__raw">
                    <p class="title">Последнее обновление</p>
                    <p v-if="equipment.date_last_update">{{ parseDate(equipment.date_last_update) }}</p>
                    <p v-else>Нет последнего обновления</p>
                </div>
            </div>

            <div class="block equipment__info">
                <h4>Биометрия</h4>

                <div class="equipment__info__raw">
                    <p class="title">Колличество моточасов</p>
                    <p v-if="equipment.motor_hours">{{ equipment.motor_hours }}</p>
                    <p v-else>Ещё не работал</p>
                </div>

            </div>
        </div>

        <div class="line">
            <div class="block equipment__info">
                <h4>Местоположение</h4>

                <div class="equipment__info__raw" v-if="equipment.coordinate">
                    <p class="title">Координаты</p>
                    <p>{{ equipment.coordinate }}</p>
                </div>
                <p v-else>Нет координат</p>

                <button-text v-if="equipment.coordinate" class="btn-sel-1" :msg="'Перейти'"
                    :path="`${equipment.id}/coord`"></button-text>
                <button-arrow v-if="equipment.coordinate" class="btn-sel-2"
                    :path="`${equipment.id}/coord`"></button-arrow>
            </div>

            <div class="block equipment__info">
                <h4>Датчик</h4>

                <div v-if="equipment.device">
                    <div class="equipment__info__raw">
                        <p class="title">Название</p>
                        <p>{{ equipment.device.name }}</p>
                    </div>
                    <div class="buttons">
                        <button-gray :msg="'Отвязать'"></button-gray>
                    </div>

                </div>
                <div v-else class="equipment__info__link-device">
                    <p>Нет привязаного устройства</p>
                    <button-gray :msg="'Привязать устройство'" @touch="openLinkDeviceModal"></button-gray>
                </div>


                <button-text v-if="equipment.device" class="btn-sel-1" :msg="'Перейти'"
                    :path="`/devices/${equipment.device.id}`"></button-text>
                <button-arrow v-if="equipment.device" class="btn-sel-2"
                    :path="`/devices/${equipment.device.id}`"></button-arrow>
            </div>

        </div>

        <div class="line">
            <div class="block equipment__info">
                <h4>Группа</h4>

                <div class="equipment__info__raw">
                    <p class="title">Имя группы</p>
                    <p v-if="equipment.group">{{ equipment.group.name_group }}</p>
                    <p v-else>Нет пренадлежит группе</p>
                </div>
                <button-gray :msg="'Переместить'" @touch="openLinkGroupModal"></button-gray>
                <button-text v-if="equipment.group" class="btn-sel-1" :msg="'Перейти'"
                    :path="`/groups/${equipment.group.id}`"></button-text>
                <button-arrow v-if="equipment.group" class="btn-sel-2"
                    :path="`/groups/${equipment.group.id}`"></button-arrow>
            </div>

            <div class="block equipment__info">
                <h4>Действия</h4>

                <div class="buttons">
                    <!-- <button-gray :msg="'Сменить название'"></button-gray> -->
                    <ByttonRed :msg="'Удалить оборудование'" @touch="openDelEquipmentModal"></ByttonRed>
                </div>

            </div>
        </div>

        <accept-del-modal v-if="isDelEquipmentModal" :nameModal="'Удаление оборудования'"
            :text="`Вы действительно хотите удалить оборудование ${equipment.name} с серийным номером ${equipment.serial_number}?`"
            @closePopup="closeDelEquipmentModal" @accept="deleteEquipment(equipment.id)"></accept-del-modal>

        <modal v-if="isLinkDeviceModal" :nameModal="'Выберете устройство'" @closePopup="closeLinkDeviceModal">
            <div class="device-modal">
                <div class="device-modal-line" v-if="get_deviceList()" v-for="device in get_deviceList()">
                    <p>{{ device.device_name }}</p>
                    <button-gray :msg="'Выбрать'" @touch="linkDevice(device.id)"></button-gray>
                </div>
            </div>

        </modal>

        <modal v-if="isLinkGroupModal" :nameModal="'Выберете группу'" @closePopup="closeLinkGroupModal">
            <div class="device-modal">
                <div class="device-modal-line" v-if="get_GroupList()" v-for="group in get_GroupList()">
                    <p>{{ group.name_group }}</p>
                    <button-gray :msg="'Выбрать'" @touch="linkGroup(group.id)"></button-gray>
                </div>
            </div>

        </modal>
    </div>
</template>

<script>
import AcceptDelModal from '@/components/modal/AcceptDelModal.vue';
import Modal from '@/components/modal/Modal.vue';
import ButtonArrow from '@/components/utils/ButtonArrow.vue';
import ButtonGray from '@/components/utils/ButtonGray.vue';
import ButtonText from '@/components/utils/ButtonText.vue';
import ByttonRed from '@/components/utils/ByttonRed.vue';
import { parseDate } from '@/helpers/utils';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'EquipmentView',

    data() {
        return {
            equipment: null,
            isLinkDeviceModal: false,
            isDelEquipmentModal: false,
            isLinkGroupModal: false
        }
    },

    computed: {
        getEquipment() {
            return this.equipment
        }
    },

    components: {
        ButtonGray,
        ByttonRed,
        ButtonText,
        ButtonArrow,
        Modal,
        AcceptDelModal
    },

    mounted() {
        this.feathEquipment();
    },

    methods: {

        async feathEquipment() {
            await this.download_eq(this.$route.params.equipmentId);
            this.equipment = this.get_eq()
            // console.log(this.equipment)
        },

        openDelEquipmentModal() {
            this.isDelEquipmentModal = true
        },

        closeDelEquipmentModal() {
            this.isDelEquipmentModal = false
        },

        async deleteEquipment(id) {
            await this.delete_equipment(id)

            this.closeDelEquipmentModal()
        },

        async openLinkDeviceModal() {
            await this.download_deviceList();
            this.isLinkDeviceModal = true
        },

        closeLinkDeviceModal() {
            this.isLinkDeviceModal = false
        },

        parseDate(date) {
            return parseDate(date)
        },

        async linkDevice(id) {
            var data = {
                "device_id": id,
                "equipment_id": this.$route.params.equipmentId
            }

            await this.link_device(data)
            // await this.feathEquipment()
            this.equipment = this.get_eq()
            this.closeLinkDeviceModal()
        },

        async openLinkGroupModal() {
            await this.download_groupList();
            this.isLinkGroupModal = true
        },

        closeLinkGroupModal() {
            this.isLinkGroupModal = false
        },

        async linkGroup(id) {

            let data = {
                'equipment_id': this.equipment.id,
                'group_id': id
            }

            await this.link_equipment_to_group(data)
            await this.feathEquipment()
            this.closeLinkGroupModal()
        },

        ...mapActions({
            download_eq: 'equipments/download_equipment',
            download_deviceList: 'devices/download_deviceList',
            download_groupList: 'groups/download_groupList',
            link_device: 'equipments/link_equipment_to_device',
            delete_equipment: 'equipments/delete_equipment',
            link_equipment_to_group: 'equipments/link_equipment_to_group'
        }),

        ...mapGetters({
            get_eq: 'equipments/get_equipment',
            get_deviceList: 'devices/get_deviceList',
            get_GroupList: 'groups/get_groupList'
        })

    }
}
</script>


<style lang="scss">
.equipment {

    .device-modal {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        width: 100%;

        .device-modal-line {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }


    &__info {
        position: relative;

        .btn-sel-1 {
            position: absolute;
            top: 10;
            margin-right: 20px;
            right: 0;
        }

        .btn-sel-2 {
            position: absolute;
            top: 10;
            margin-right: 20px;
            right: 0;
        }

        .buttons {
            display: flex;
            flex-direction: column;
            grid-gap: 20px;
        }

        &__link-device {
            display: flex;
            flex-direction: column;
            grid-gap: 20px;
        }

        &__raw {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
                text-align: start;
                font-weight: 500;
            }

            p {
                text-align: end;
            }
        }
    }
}
</style>