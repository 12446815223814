<template>
    <div class="hint">
        <p>{{ data }}</p>
    </div>
</template>
  
<script>
export default {
    name: 'HintUI',

    props: {
        data: {
            type: String,
            default: 'Empty Hint'
        }
    }
}
</script>

<style scoped lang="scss">
.hint {
    position: fixed;
    top: 100px;
    right: 20px;
    max-width: 350px;
    background-color: #FBEEDB;
    z-index: 100;
    color: black;
    padding: 15px;
    animation-name: hint;
    animation-duration: 5s;
    animation-fill-mode: forwards;
    transform: translateX(150%);

    @media (max-width: 900px) {
        top: unset;
        bottom: 50px;
    }

    @keyframes hint{
        10%{ transform: translateX(0) }
           
        90%{transform: translateX(0)}   
            
        100%{transform: translateX(150%)}
            
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 5px;
        height: 100%;
        background: #E99F42;
    }

    & p {
        font-size: clamp(12px, 1vw, 16px);
        line-height: 1.5;
        font-weight: 600;
    }
}
        
</style>