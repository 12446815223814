<template>
    <router-link class="btn-arrow" :to="path" v-on:Click="touch">
        >
    </router-link>
</template>

<script>
export default {
    name: 'ButtonArrow',
    props: {
        msg: String,
        path: String,
    },
    
    methods: {

        touch() {
            this.$emit('touch')
        }
    }
}
</script>

<style lang="scss">

.btn-arrow {
    text-align: center;
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    color: rgb(30, 30, 30);
}

.btn-arrow:hover {
    color: rgb(0, 0, 0);
    font-weight: 700;
}

.btn-arrow:active {
    color: rgb(0, 0, 0);
}

</style>
