<template>
    <div class="sing-in">
        <div class="sing-in__logo">
            <h1><a href="/">TRAS</a></h1>
            <p>Система автоматизации проката инструмента</p>
        </div>
        <div class="sing-in__body">
            <h3>Авторизация</h3>
            <login-selector @togle="togleInput"></login-selector>
            <form :key="noValids" v-if="isPhone">
                <vue-tel-input v-model="phone" :autoFormat="true" mode="international" :enabledCountryCode="true"
                    :placeholder="'Введите номер телефона'" :defaultCountry="'RU'"
                    :dropdownOptions="{ showDialCodeInSelection: true }"
                    :class="{ 'no-valid': noValids.phone }"></vue-tel-input>
                <!-- <input type="tel" placeholder="Номер телефона" v-model="phone" :class="{ 'no-valid': noValids.phone }"> -->
                <input type="password" placeholder="Пароль" v-model="password"
                    :class="{ 'no-valid': noValids.password }">
                <button-gray :text="'Войти'" :path="``" @touch="singIn(recaptcha)"></button-gray>
            </form>
            <form v-else>
                <input type="text" placeholder="Логин" v-model="login" :class="{ 'no-valid': noValids.login }">
                <input type="password" placeholder="Пароль" v-model="password" :class="{ 'no-valid': noValids.phone }">
                <button-gray :text="'Войти'" :path="``" @touch="singIn(recaptcha)"></button-gray>
            </form>
            <p class="error" v-if="error">{{ error }}</p>
            <button-text class="back" :msg="'Назад'" :path="`/`"></button-text>
        </div>
    </div>
</template>

<script>
// import VueRecaptcha from 'vue-recaptcha-v3'
import ButtonGray from "@/components/utils/ButtonGray.vue"
import ButtonText from "@/components/utils/ButtonText.vue";
import LoginSelector from "@/components/utils/LoginSelector.vue";
import PhoneInput from "@/components/utils/PhoneInput.vue";
import { parsePhone } from "@/helpers/utils";
import { mapActions, mapGetters } from "vuex";
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'
// import Vue from "vue";

import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

// Vue.use(VueReCaptcha, { siteKey: "6Lcb51MqAAAAAJWfq0Z0oWiQRgHlX4JCkAj9zOtZ" });

export default {
    name: 'SingIn',

    data() {
        return {
            login: null,
            password: null,
            error: null,
            isPhone: true,
            phone: null,
            noValids: {
                password: false,
                login: false,
                phone: false
            },
        }
    },

    computed: {

        getError() {

        }

    },

    components: {
        ButtonGray,
        ButtonText,
        LoginSelector,
        PhoneInput,
        VueTelInput
    },

    mounted() {
    },

    setup() {
        const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

        const recaptcha = async () => {
            // (optional) Wait until recaptcha has been loaded.
            await recaptchaLoaded()

            // Execute reCAPTCHA with action "login".
            const token = await executeRecaptcha('login')

            return token;
            // Do stuff with the received token.
        }

        return {
            recaptcha
        }
    },

    methods: {

        async singIn(recaptcha) {

            if (this.password == null || this.password == '') {
                this.error = "Заполните поля"
                this.noValids.password = true
                return;
            }

            this.noValids.password = false
            this.error = null

            let data = {
                "login": "",
                "phone_number": "",
                "password": this.password.trimEnd(),
                "site_token": await recaptcha()
            };

            if (this.isPhone) {
                if (this.phone == null || this.phone == "") {
                    this.error = "Заполните поля"
                    this.noValids.phone = true
                    return;
                }
                this.noValids.phone = false
                this.error = null
                data.phone_number = parsePhone(this.phone)
            } else {
                if (this.login == null || this.login == '') {
                    this.error = "Заполните поля"
                    this.noValids.login = true
                    return;
                }
                this.noValids.login = false
                this.error = null
                data.login = this.login.trimEnd()
            }
            this.auth(data);

        },

        togleInput(isPhone) {
            this.isPhone = isPhone
        },

        ...mapActions({
            auth: 'auth/sing_in',
        })
    }
}
</script>


<style lang="scss">
.sing-in {
    width: 100%;
    display: flex;
    align-items: center;
    // margin-top: 40%;
    height: 600px;

    &__logo {
        width: 50%;
        text-align: center;

        h1 {
            font-size: 60px;
            font-weight: 400;

            a {
                text-decoration: none;
                color: black;
            }
        }

        p {
            width: 40%;
            margin: auto;
        }
    }

    &__body {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 80px;

        form {
            display: flex;
            flex-direction: column;
            grid-gap: 50px;
            width: 50%;
            margin: auto;

            input {
                border: none;
                border-radius: 5px;
                background-color: rgb(187, 187, 187);
                padding: 10px;

            }


        }

        .no-valid {
            border: solid 1px;
            border-color: rgb(255, 119, 119);
            background-color: rgb(254, 218, 218);
        }

        .back {
            padding-top: 50px;
        }

        .error {
            text-align: center;
            color: red;
        }
    }
}

@media (max-width: 990px) {

    .sing-in {
        flex-direction: column;
        margin-top: 5%;
        grid-gap: 100px;

        &__logo {
            width: 100%;

            p {
                width: 70%;
                margin: auto;
            }
        }

        &__body {
            width: 100%;
            margin: auto;

            form {
                width: 80%;
            }
        }

        .back {
            width: fit-content;
            margin: auto;
            text-align: center;
        }
    }

}
</style>