<template>
    <h1 class="head">{{ text }}</h1>
</template>

<script>
export default {
    name: 'ButtonGray',
    props: {
        text: String
    },
    
    methods: {
    }
}
</script>

<style lang="scss">

.head {
    width: fit-content;
    text-align: center;
    margin: auto;

    h1 {
        font-size: 40px;
    }
}

</style>
