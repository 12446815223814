<template>

    <div class="group-add frame">
        <h1>Создать группу</h1>

        <div class="line">
            <div class=" block">
                <h4>Информация</h4>
                <p>Группа это универсальный инструмент разделения прав доступа между работниками проката.</p>
                <p>К группе принадлежит оборудование и устройства, поэтому группу, можно интерпритировать, как склад или подразделение.</p>
            </div>
            <div class="group-add__main block">
                <h4>Остновное</h4>
                <form>
                    <input type="text" placeholder="Имя группы" v-model="nameGroup">
                    <button-gray :msg="'Создать'" :path="''" @touch="createGroup"></button-gray>
                </form>
            </div>
        </div>
        <div class="line">
            <div class="group-add__users block">
                <h4>Пользователи</h4>
                <div class="group-add__users__raw" v-if="usersAccepted" v-for="user in usersAccepted">
                    <p>{{ user.name }}</p>
                    <button-gray :msg="'Отвязать'" :path="''" @touch="unlinckUser(user)"></button-gray>
                </div>
                <div class="line-hor" v-if="usersAccepted.length != 0"></div>
                <div class="group-add__users__raw" v-if="userList" v-for="user in userList">
                    <p>{{ user.name }}</p>
                    <button-gray :msg="'Привязать'" :path="''" @touch="linckUser(user)"></button-gray>
                </div>
            </div>

            <div class="group-add__search block">
                <h4>Найти пользователя</h4>
                <form action="">
                    <input type="text" placeholder="Логин" v-model="userLogin">
                    <button-gray :msg="'Найти'" :path="''" @touch="searchUser"></button-gray>
                </form>
            </div>
        </div>

    </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ButtonText from '@/components/utils/ButtonText.vue';
import ButtonGray from '@/components/utils/ButtonGray.vue';

export default {
    name: 'GroupAddView',

    data() {
        return {
            nameGroup: null,
            userList: null,
            usersAccepted: [],
            userLogin: null
        }
    },

    props: {

    },

    components: {
        ButtonText,
        ButtonGray
    },

    mounted() {
        this.fetchUserList()
    },

    methods: {

        async fetchUserList() {
            await this.download_userList();
            this.userList = this.get_userList();
            // console.log(this.userList)
        },

        closePopup() {
            this.$emit('closePopup')
        },

        async searchUser() {
            // console.log(this.userLogin)
            await this.search_user(this.userLogin.trimEnd());
            // if (this.get_searchUser() != null) {

            //     if (!!this.userList.find(u => u.id == this.get_searchUser().id)) {
            //         this.usersAccepted.push(this.get_searchUser())
            //         this.userList = this.userList.filter(u => u.id !== this.get_searchUser().id)
            //     }

            //     if (!!!this.usersAccepted.find(u => u.id == this.get_searchUser().id)) {
            //         this.usersAccepted.push(this.get_searchUser())
            //     }
            //     this.userLogin = null
            // }
        },

        linckUser(user) {
            this.usersAccepted.push(user)
            this.userList = this.userList.filter(u => u.id !== user.id)
        },

        unlinckUser(user) {
            this.userList.push(user)
            this.usersAccepted = this.usersAccepted.filter(u => u.id !== user.id)
        },

        createGroup() {
            var users = this.usersAccepted.map(u => u.name)

            // for (i in this.usersAccepted) {
            //     users.push(this.usersAccepted[i])
            // }

            var data = {
                'name': this.nameGroup.trimEnd(),
                'users': users
            }
            console.log(data)

            this.create_group(data)
            this.closePopup()
        },

        ...mapActions({
            download_userList: 'groups/download_userList',
            search_user: 'groups/search_user',
            create_group: 'groups/create_group'
        }),

        ...mapGetters({
            get_userList: 'groups/get_userList',
            get_searchUser: 'groups/get_searchUser'
        })
    }
}
</script>

<style lang="scss">
.group-add {

    form {
        display: flex;
        flex-direction: column;
        grid-gap: 50px;
        width: 80%;
        margin: auto;

        input {
            border: none;
            border-radius: 5px;
            background-color: rgb(187, 187, 187);
            padding: 10px;
        }

        @media (max-width: 900px) {
            width: 100%;
        }
    }

    &__users {

        .line-hor {
            margin: 10px 0;
        }

        &__raw {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;

        }
    }
}
</style>