import { singOut } from '@/helpers/utils';
import Cookies from 'js-cookie';
import api from '@/helpers/api';
import router from '@/router';

export const Devices = {
    state: () => ({
        devices: null,
        device: null,
        deviceList: null
    }),
    getters: {
        get_devices(state) {
            return state.devices

        },
        get_device(state) {
            return state.device

        },

        get_deviceList(state) {
            return state.deviceList

        },
    },
    mutations: {
        set_devices(state, devices) {
            state.devices = devices
        },

        set_device(state, device) {
            state.device = device
        },

        set_deviceList(state, deviceList) {
            state.deviceList = deviceList
        },
    },
    actions: {

        async download_devices({ commit, state }) {
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('authToken')
                    }
                }

                const res = await api.get('/devices', config)
                var devices = res.data
                for (const i in devices) {
                    devices[i]['hidden'] = true
                }
                // console.log(res.data)
                commit('set_devices', res.data)
            } catch (error) {
                if (error.response.status == 401) {
                    singOut();
                }
                // console.log(error);
            }
            
        },

        async download_deviceList({ commit, state }) {
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('authToken')
                    }
                }

                const res = await api.get('/devices?is_list=true', config)
                // console.log(res.data)
                commit('set_deviceList', res.data)
            } catch (error) {
                if (error.response.status == 401) {
                    singOut();
                }
                // console.log(error);
            }
            
        },

        async download_device({ commit, state }, id) {
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('authToken')
                    }
                }

                const res = await api.get('/devices/' + id, config)

                commit('set_device', res.data)
            } catch (error) {
                if (error.response.status == 401) {
                    singOut();
                }
                // console.log(error);
            }
            
        },

        async link_device({ commit, state }, data) {
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('authToken')
                    }
                }

                const res = await api.post('/devices/link',data ,config)
                commit('set_hint', 'Устройство было успешно добавлено', { root: true })
                router.back()
                // commit('set_devices', res.data)
            } catch (error) {
                if (error.response.status == 401) {
                    singOut();
                }
                commit('set_hint', error.response.data.massage || 'Ошибка', { root: true })
                // console.log(error);
            }
            
        },

        async link_device_to_group({ commit, state }, data) {
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('authToken')
                    }
                }

                const res = await api.post('/groups/link/device', data, config)
                commit('set_hint', 'Вы успешно привязали устройстов к другой группе', { root: true })
            } catch (error) {
                if (error.response.status == 401) {
                    singOut();
                }
                commit('set_hint', error.response.data.massage || 'Ошибка', { root: true })
                // console.log(error);
            }
            
        },

        async change_name_device({ commit, state }, data) {
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('authToken')
                    }
                }

                const res = await api.post('/devices/change/name', data, config)
                commit('set_hint', `Вы сменили название устройства на ` + data.new_name, { root: true })
                // router.back()
                commit('set_devices', res.data)
            } catch (error) {
                if (error.response.status == 401) {
                    singOut();
                }
                commit('set_hint', error.response.data.massage || 'Ошибка', { root: true })
                // console.log(error);
            }
            
        },

        async delete_device({ commit, state }, data) {
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('authToken')
                    }
                }

                const res = await api.post('/devices/delete?device_id=' + data, {},config)
                commit('set_hint', res.data || 'Действие', { root: true })
                // router.back()
                // commit('set_devices', res.data)
            } catch (error) {
                if (error.response.status == 401) {
                    singOut();
                }
                // console.log(error);
            }
            
        },
    },

    namespaced: true
}