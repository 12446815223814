<template>
    <div class="start-auth">
        <div class="start-auth__logo">
            <h1><a href="/">TRAS</a></h1>
            <p>Система автоматизации проката инструмента</p>
        </div>
        <div class="start-auth__body">
            <div class="start-auth__body__buttons">
                <button-gray :msg="'Войти'" :path="`/login`"></button-gray>
                <button-gray :msg="'Зарегистрироваться'" :path="`/registration`"></button-gray>
            </div>
            
        </div>
        
    </div>
</template>

<script>
import ButtonGray from "@/components/utils/ButtonGray.vue";
import HeaderCast from "@/components/main/HeaderCast.vue";

export default {
    name: 'StartAuthView',

    data() {
        return {
        }
    },

    computed: {
    },

    components: {
        ButtonGray,
        HeaderCast,
    },

    mounted() {
    },

    methods: {

    }
}
</script>


<style lang="scss">
.start-auth {
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // grid-gap: 80px;

    &__logo {
        width: 50%;
        text-align: center;

        h1 {
            font-size: 60px;
            font-weight: 400;

            a {
                text-decoration: none;
                color: black;
            }
        }

        p {
            width: 40%;
            margin: auto;
        }
    }

    &__body {
        width: 50%;
        

        &__buttons {
            display: flex;
            flex-direction: column;
            grid-gap: 20px;
            width: 50%;
            margin: auto;
        }
    }
}

@media (max-width: 990px) {

.start-auth {
    height: 500px;
    flex-direction: column;
    margin-top: 5%;
    grid-gap: 100px;

    &__logo {
        width: 100%;

        p {
            width: 70%;
            margin: auto;
        }
    }

    &__body {
        width: 100%;
        

        &__buttons {
            width: 70%;
        }
    }
}

}
</style>