import { createStore } from 'vuex'
import { Device } from './device'
import { Auth } from './auth'
import { Equipments } from './equipments'
import { Devices } from './devices'
import { Profile } from './profile'
import { Groups } from './groups'
import { Coordinate } from './coordinate'

export default createStore({
  state: {
    auth: null,
    equipments: null,
    devices: null,
    profile: null,
    groups: null,
    hint: null,
    coordinate: null
  },
  getters: {

    get_hint() {
      return state.hint;
    }
  },
  mutations: {

    set_hint(state, value) {
      state.hint = value;
      console.log(value)

      setTimeout(() => {
        state.hint = null;
      }, 5000);
    }
  },
  actions: {
  },
  modules: {
    auth: Auth,
    equipments: Equipments,
    devices: Devices,
    profile: Profile,
    groups: Groups,
    coordinate: Coordinate
  }
})
