<template>
    <router-link class="btn-gray" :to="path" v-on:Click="touch">
        {{ msg }}
    </router-link>
</template>

<script>
export default {
    name: 'ButtonGray',
    props: {
        msg: String,
        path: {
            type: String,
            default: ''
        }
    },
    
    methods: {

        touch() {
            this.$emit('touch')
        }
    }
}
</script>

<style lang="scss">

.btn-gray {
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    color: rgb(231, 230, 230);
    background-color: rgb(44, 44, 44);
    padding: 10px;
    
}

.btn-gray:hover {
    color: rgb(231, 230, 230);
    background-color: rgb(34, 34, 34);
}

.btn-gray:active {
    color: rgb(141, 141, 141);
    background-color: rgb(34, 34, 34);
}

</style>
