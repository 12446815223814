import axios from 'axios';
import Cookies from "js-cookie";
import api from '@/helpers/api';
import router from '@/router';

const cookiAuthToken = Cookies.get("authToken")

export const Auth = {
    state: () => ({
        authToken: !!cookiAuthToken,
    }),
    getters: {
        get_authToken(state) {
            return state.authToken

        },
    },
    mutations: {
        set_authToken(state, authToken) {
            state.authToken = authToken
        },
    },
    actions: {
        async sing_in({ commit, state }, data) {

            try {
                const config = {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                    // data: data
                }

                const res = await api.post('/login/auth', data, config)

                commit('set_authToken', !!res.data.token)
                Cookies.set("authToken", res.data.token);
                router.push('/equipments');

            } catch (error) {
                commit('set_hint', error.response.data.massage || 'Ошибка', { root: true })
                // console.log(error);
            }

            // await commit('set_authToken', true);
            // console.log(state.authToken);
            // Cookies.set("authToken", token);
        },

        async registration({ commit, state }, data) {

            try {
                const config = {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }

                const res = await api.post('/login/registration', data, config)

                // commit('set_authToken', !!res.data.token)
                // Cookies.set("authToken", res.data.token);
                commit('set_hint', "Вы успешно зарегистрировались", { root: true })
                router.push('/login');

            } catch (error) {
                commit('set_hint', error.response.data.massage || 'Ошибка', { root: true })
                // console.log(error);
            }

            // await commit('set_authToken', true);
            // console.log(state.authToken);
            // Cookies.set("authToken", token);
        },

        async sing_out({ commit, state }) {
            Cookies.remove("authToken");
            await commit('set_authToken', false);
            // console.log(state.authToken);
            router.push('/');
        }
    },

    namespaced: true
}