import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { VueReCaptcha } from 'vue-recaptcha-v3'
// import inputmask from 'inputmask'

createApp(App).use(store).use(router)
.use(VueReCaptcha, { siteKey: '6Lcb51MqAAAAAJWfq0Z0oWiQRgHlX4JCkAj9zOtZ' })
.use(VueAxios, axios)
// .use('input-search', InputSearch)
.mount('#app')
