import store from '@/store';
import router from '@/router';

export const parseCoords = (stringCoords) => {
    var parseCoords = [];
    for (const coord of stringCoords) {
        parseCoords.push([
            Number(coord.coordinate.split(' ')[0]),
            Number(coord.coordinate.split(' ')[1])
        ])
    }
    return parseCoords
}

export const separateCarts = (data) => {
    var dataLeft = [];
    var dataRight = [];
    var chack = true;
    for (const block of data) {
        if (chack) {
            dataLeft.push(block)
        }else {
            dataRight.push(block)
        }
        chack = !chack
    }

    return {'dataLeft': dataLeft, 'dataRight': dataRight}
}

export const parsePhone = (phone) => {
    return phone.trimEnd().replace('(', '').replace(')', '').replaceAll(' ', '')
}

export const parseDate = (stringDate) => {
    const d = new Date(stringDate);
    var time = (d.getHours() < 10 ? ("0" + d.getHours()) : d.getHours()) + ':' + (d.getMinutes() < 10 ? ("0" + d.getMinutes()) : d.getMinutes()) + " " + d.getDate() + '.' + (d.getMonth() + 1) + '.' + d.getFullYear()
    return time
}

export const singOut = () => {
    store.dispatch('auth/sing_out');
    router.push('/');
}