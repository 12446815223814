<template>
    <div class="date" v-if="date">
        <p class="date__time">{{ time }}</p>
        <p class="date__date">{{ dateInner }}</p>
    </div>
    <div class="date-min" v-if="date">
        <img src="@/assets/time.svg" alt="">
        <div class="tooltip-text">
            <p class="date-min__time">{{ time }}</p>
            <p class="date-min__date">{{ dateInner }}</p>
        </div>

    </div>
</template>

<script>
import { parseDate } from '@/helpers/utils';

export default {
    name: 'TimeUpdate',

    props: {
        date: {
            default: null,
            type: String
        },

    },

    data() {
        return {
            dateInner: null,
            time: null,
        }
    },

    mounted() {
        this.loaded()
    },

    methods: {

        loaded() {
            if (this.date != "") {
                var d = parseDate(this.date).split(" ")
                this.dateInner = d[1]
                this.time = d[0]
            }
        },

        touch() {
            this.$emit('touch')
        }
    }
}
</script>

<style lang="scss">
.date {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__time {
        margin-top: 0;
        margin-bottom: 5px;
        font-weight: 400;
    }

    &__date {
        margin: 0;
    }

    @media (max-width: 900px) {
        display: none;
    }
}

.date-min {

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__time {
        margin-top: 0;
        margin-bottom: 5px;
        font-weight: 400;
    }

    &__date {
        margin: 0;
    }

    .tooltip-text {
        visibility: hidden;
        position: absolute;
        bottom: 10px;
        left: 0;
        background-color: #FBEEDB;
        padding: 10px;
        border-radius: 10px;
        min-width: 200px;

        box-shadow: 0 0 5px 1px rgba(40, 40, 40, 0.081);
    }

    @media (min-width: 900px) {
        display: none;
    }
}

.date-min:hover {

    .tooltip-text {
        visibility: visible;
        /* Вот такое чудо! */
    }
}
</style>
