<template>
    <div class="device-map" id="device-map">

    </div>
</template>

<script>
import ymaps from 'ymaps';
import { parseCoords } from '@/helpers/utils'

export default {
    name: 'DeviceMap',
    props: {
        coord: {
            required: true,
            type: Array
        }
    },
    data() {
        return {

        }
    },
    async mounted() {
        ymaps
            .load()
            .then(maps => {
                const coord = parseCoords(this.coord)
                // console.log(coord)
                
                if (coord.length != 0) {
                    const map = new maps.Map('device-map', {
                        center: [coord[coord.length - 1][0], coord[coord.length - 1][1]],
                        zoom: 14
                    });

                    var placemark = new maps.Placemark([coord[coord.length - 1][0], coord[coord.length - 1][1]], {}, {
                        preset: 'islands#blueGlyphCircleIcon',
                        iconGlyphColor: 'blue',
                    });


                    var myPolyline = new maps.Polyline(coord, {
                        hintContent: "Ломаная"
                    }, {
                        draggable: false,
                        strokeColor: '#0888E4',
                        cursor: "pointer",
                        strokeWidth: 4,
                    });

                    map.geoObjects.add(myPolyline);
                    map.geoObjects.add(placemark);
                }else {
                    const map = new maps.Map('device-map', {
                        center: [59, 50],
                        zoom: 14
                    });
                }

            })
            .catch(error => console.log('Failed to load Yandex Maps', error));
    },
    methods: {

    }
}
</script>

<style lang="scss">
.device-map {
    width: 100%;

    height: 700px;
}
</style>