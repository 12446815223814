<template>
    <div class="selector">
        <div class="selector__inner" :class="{'selected': isPhone}">
            <router-link :to="''" v-on:click="inPhone">По телефону</router-link>
        </div>
        <div class="selector__inner rigth" :class="{'selected': !isPhone}">
            <router-link :to="''" v-on:click="inLogin">По логину</router-link>
        </div>
        
    </div>
</template>
  
<script>
export default {
    name: 'LoginSelector',

    props: {
        
    },

    data() {
        return {
            isPhone: true
        }
    },

    methods: {
        inPhone() {
            this.isPhone = true;
            this.$emit('togle', this.isPhone)
        },

        inLogin() {
            this.isPhone = false;
            this.$emit('togle', this.isPhone)
        }
    }
}
</script>

<style scoped lang="scss">

.selector {
    display: flex;
    width: 70%;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    margin-bottom: 20px;
    box-shadow: 0 0 2px 2px rgba(40, 40, 40, 0.163);
    overflow: hidden;

    @media (min-width: 990px) {
        width: 50%;
    }

    a {
        text-decoration: none;
        color: black;
    }

    a:hover {
        color: rgb(111, 111, 111);
    }

    &__inner {
        width: 50%;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .rigth {
        text-align: end;
    }

    .selected {
        background-color: rgb(44, 44, 44);
        a {
            color: rgb(231, 230, 230);
        }

        a:hover {
            color: rgb(201, 201, 201);
        }
        
    }
}
        
</style>