<template>
    <div class="modal">
        <div class="pop-up">
            <div class="pop-up__header">
                <h2>{{ nameModal }}</h2>
                <!-- <button-text :msg="'Закрыть'" :path="''" @touch="closePopup"></button-text> -->
            </div>
            <form @submit.prevent="accept">
                <div class="pop-up__content">
                    <slot></slot>
                </div>
                <div class="pop-up__footer">
                </div>
            </form>

        </div>
        <div class="blur" @click="closePopup">

        </div>
    </div>

</template>

<script>
import ButtonText from '../utils/ButtonText.vue';

export default {
    name: 'Modal',

    props: {
        nameModal: String
    },

    components: {
        ButtonText

    },

    methods: {

        closePopup() {
            this.$emit('closePopup')
        },

        accept() {
            this.$emit('accept')
        }
    }
}
</script>

<style lang="scss">
.blur {
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
    z-index: 1;
    animation-name: blur-1;
    animation-duration: 0.2s;
    animation-fill-mode: initial;


    @keyframes blur-1 {
        0% {
            opacity: 0%
        }

        100% {
            opacity: 100%
        }

    }
}

.modal {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;



    .pop-up {
        z-index: 99;
        background-color: white;
        border-radius: 20px;
        padding: 30px;
        margin: auto;
        // width: fit-content;
        max-width: 80%;
        width: 60%;
        box-shadow: 0 0 20px 0 #4949494a;

        animation-name: modal;
        animation-duration: 0.2s;
        animation-fill-mode: initial;


        @keyframes modal {
            0% {
                opacity: 0%
            }

            100% {
                opacity: 100%
            }

        }


        &__header {
            display: flex;
            justify-content: space-between;
            align-items: start;

            h2 {
                margin-top: 0;
            }
        }

        &__content {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__footer {
            // min-width: 500px;
            display: flex;
            justify-content: space-between;
        }


    }
}

@media (max-width: 990px) {
    .modal {
        .pop-up {

            form {
                width: 100%;
            }
        }
    }

}
</style>