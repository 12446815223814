<template>
    <div v-if="getDevice" class="frame device">
        <h1 :key="getDevice.name">{{ getDevice.name }}</h1>

        <div class="line">

            <div class="block device__info">
                <h4>Информация</h4>

                <div class="device__info__raw">
                    <p class="title">Серийный номер</p>
                    <p v-if="getDevice.serial_number">{{ getDevice.serial_number }}</p>
                    <p v-else>Нет серийного номера</p>
                </div>

                <div class="line-hor"></div>

                <div class="device__info__raw">
                    <p class="title">Мак адрес</p>
                    <p v-if="getDevice.mac_address">{{ getDevice.mac_address }}</p>
                    <p v-else>Нет мак адреса</p>
                </div>

                <div class="line-hor"></div>

                <div class="device__info__raw">
                    <p class="title">Время последнего обновления</p>
                    <p v-if="getDevice.date_last_update">{{ parseDate(getDevice.date_last_update) }}</p>
                    <p v-else>Нет последнего обновления</p>
                </div>
            </div>

            <div class="block device__info">
                <h4>Оборудование</h4>
                <div v-if="getDevice.equipment">
                    <div class="device__info__raw">
                        <p class="title">Название оборудования</p>
                        <p>{{ getDevice.equipment.name }}</p>
                    </div>

                    <div class="line-hor"></div>

                    <div class="device__info__raw">
                        <p class="title">Серийный номер</p>
                        <p>{{ getDevice.equipment.serial_number }}</p>
                    </div>
                </div>
                <div v-else>
                    <p>Не стоит на оборудовании</p>
                </div>
                <button-text v-if="getDevice.equipment" class="btn-sel-1" :msg="'Перейти'"
                    :path="`/equipments/${getDevice.equipment.id}`"></button-text>
                <button-arrow v-if="getDevice.equipment" class="btn-sel-2"
                    :path="`/equipments/${getDevice.equipment.id}`"></button-arrow>
            </div>

        </div>

        <div class="line">

            <div class="block device__info">
                <h4>Группа</h4>
                <div class="device__info__raw">
                    <p class="title">Название группы</p>
                    <p v-if="getDevice.group">{{ getDevice.group.name_group }}</p>
                    <p v-else>Не привязан к группе</p>
                </div>
                <button-text v-if="getDevice.group" class="btn-sel-1" :msg="'Перейти'"
                    :path="`/groups/${getDevice.group.id}`"></button-text>
                <button-arrow v-if="getDevice.group" class="btn-sel-2"
                    :path="`/groups/${getDevice.group.id}`"></button-arrow>

                <button-gray :msg="'Переместить'" @touch="openLinkGroupModal"></button-gray>

            </div>

            <div class="block device__info">
                <h4>Действия</h4>
                <div class="buttons">
                    <button-gray :msg="'Сменить название'" @touch="openChangeNameModal"></button-gray>
                    <ByttonRed :msg="'Удалить устройство'" @touch="openDelDeviceModal"></ByttonRed>
                </div>

            </div>

        </div>

        <accept-del-modal v-if="isDelDeviceModal" :nameModal="'Удаление устройства'"
            :text="`Вы действительно хотите удалить устройство ${device.id}?`" @closePopup="closeDelDeviceModal"
            @accept="deleteDevice"></accept-del-modal>

        <modal v-if="isChangeNameModal" :nameModal="'Смена названия'" @closePopup="closeChangeNameDevice">
            <form action="">
                <input type="text" placeholder="Новое имя" v-model="newName">
                <button-gray :msg="'Сменить'" @touch="changeName"></button-gray>
            </form>
        </modal>

        <modal v-if="isLinkGroupModal" :nameModal="'Выберете группу'" @closePopup="closeLinkGroupModal">
            <div class="device-modal">
                <div class="device-modal-line" v-if="get_GroupList()" v-for="group in get_GroupList()">
                    <p>{{ group.name_group }}</p>
                    <button-gray :msg="'Выбрать'" @touch="linkGroup(group.id)"></button-gray>
                </div>
            </div>

        </modal>
    </div>
</template>

<script>
import AcceptDelModal from '@/components/modal/AcceptDelModal.vue';
import Modal from '@/components/modal/Modal.vue';
import ButtonArrow from '@/components/utils/ButtonArrow.vue';
import ButtonGray from '@/components/utils/ButtonGray.vue';
import ButtonText from '@/components/utils/ButtonText.vue';
import ByttonRed from '@/components/utils/ByttonRed.vue';
import { parseDate } from '@/helpers/utils';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'DeviceView',

    data() {
        return {
            device: null,
            isDelDeviceModal: false,
            isChangeNameModal: false,
            newName: "",
            isLinkGroupModal: false
        }
    },

    computed: {
        getDevice() {
            return this.device
        }
    },

    components: {
        ButtonGray,
        ByttonRed,
        ButtonText,
        ButtonArrow,
        AcceptDelModal,
        Modal
    },

    mounted() {
        this.feathDevice();
    },

    methods: {

        async feathDevice() {
            await this.download_dev(this.$route.params.deviceId);
            this.device = this.get_dev()
            // console.log(this.device)
        },

        openDelDeviceModal() {
            this.isDelDeviceModal = true
            // console.log(this.isDelDeviceModal)
        },

        closeDelDeviceModal() {
            this.isDelDeviceModal = false
        },

        async deleteDevice() {
            await this.delete_device(this.device.id)
            this.$router.back()
        },

        openChangeNameModal() {
            this.isChangeNameModal = true
        },

        closeChangeNameDevice() {
            this.isChangeNameModal = false
        },

        async changeName() {
            if (this.newName == "") {
                return;
            }

            let data = {
                "new_name": this.newName.trimEnd(),
                "device_id": this.device.id
            }

            await this.change_name_device(data)
            await this.feathDevice()
            this.closeChangeNameDevice()
        },

        parseDate(date) {
            return parseDate(date)
        },

        async openLinkGroupModal() {
            await this.download_groupList();
            this.isLinkGroupModal = true
        },

        closeLinkGroupModal() {
            this.isLinkGroupModal = false
        },

        async linkGroup(id) {

            let data = {
                'device_id': this.device.id,
                'group_id': id
            }

            await this.link_device_to_group(data)
            await this.feathDevice()
            this.closeLinkGroupModal()
        },

        ...mapActions({
            download_dev: 'devices/download_device',
            delete_device: 'devices/delete_device',
            change_name_device: 'devices/change_name_device',
            download_groupList: 'groups/download_groupList',
            link_device_to_group: 'devices/link_device_to_group'
        }),

        ...mapGetters({
            get_dev: 'devices/get_device',
            get_GroupList: 'groups/get_groupList'
        })

    }
}
</script>


<style lang="scss">
.device {

    .device-modal {
        width: 80%;

        @media (max-width: 900px) {
            width: 100%;
        }
    }

    .device-modal-line {

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    form {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        width: 70%;
        margin: auto;

        input {
            border: none;
            border-radius: 5px;
            background-color: rgb(187, 187, 187);
            padding: 10px;
        }
    }

    &__info {
        position: relative;

        .buttons {
            display: flex;
            flex-direction: column;
            grid-gap: 20px;
        }

        .btn-sel-1 {
            position: absolute;
            top: 10;
            margin-right: 20px;
            right: 0;
        }

        .btn-sel-2 {
            position: absolute;
            top: 10;
            margin-right: 20px;
            right: 0;
        }

        &__raw {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
                text-align: start;
                font-weight: 500;
            }

            p {
                text-align: end;
            }
        }
    }

}
</style>