<template>
    <div v-if="isMoreWindow" class="blur" @click="closeMoreWindow">

    </div>
    <div v-if="isMoreWindow" class="more-window">
        <router-link class="more-window__title" :to="'/equipments'" v-on:click="closeMoreWindow">TRAS</router-link>
        <nav class="more-window__nav">
            <router-link class="link" :to="'/equipments'" v-on:click="closeMoreWindow">Оборудование</router-link>
            <div class="line-hor"></div>
            <router-link class="link" :to="'/devices'" v-on:click="closeMoreWindow">Датчики</router-link>
            <div class="line-hor"></div>
            <router-link class="link" :to="'/profile'" v-on:click="closeMoreWindow">Профиль</router-link>
            <div class="line-hor"></div>
        </nav>
        <router-link class="more-window__sing-out-btn" :to="''" v-on:Click="singOut">Выйти</router-link>

    </div>
    <div class="header">
        <div class="inner">
            <div class="header__title">
                <router-link :to="'/equipments'">TRAS</router-link>
            </div>
            <nav class="header__nav">
                <router-link class="link" :to="'/equipments'">Оборудование</router-link>
                <router-link class="link" :to="'/devices'">Датчики</router-link>
                <router-link class="link" :to="'/profile'">Профиль</router-link>
            </nav>
            <router-link class="sing-out-btn" :to="''" v-on:Click="singOut">Выйти</router-link>
            <div class="more-btn" @click="openMoreWindow">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </div>
        </div>

    </div>

</template>

<script>
import ButtonRed from '../utils/ByttonRed.vue';
import { singOut } from '@/helpers/utils';

export default {
    name: 'HeaderMain',
    data() {
        return {
            isMoreWindow: false,
        }
    },

    components: {
        ButtonRed
    },

    mounted() {
    },

    methods: {

        openMoreWindow() {
            this.isMoreWindow = true;
        },

        closeMoreWindow() {
            this.isMoreWindow = false;
        },

        async singOut() {
            singOut();
        },
    }
}
</script>

<style lang="scss">
.blur {
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(3px);
    z-index: 15;

    animation-name: blur;
    animation-duration: 0.1s;
    animation-fill-mode: initial;


    @keyframes blur {
        0% {
            opacity: 0%
        }

        100% {
            opacity: 100%
        }

    }
}

.more-window {
    z-index: 100;
    position: fixed;
    top: 0;
    right: 0;
    background-color: white;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.227);
    padding: 50px 30px;
    border-bottom-left-radius: 10px;

    animation-name: more;
    animation-duration: 0.1s;
    animation-fill-mode: initial;


    @keyframes more {
        0% {
            transform: translateX(100%)
        }

        100% {
            transform: translateX(0)
        }

    }

    &__title {
        text-decoration: none;
        color: black;
        font-weight: 500;
        font-size: 25px;
        margin: 0 20px;

    }

    &__nav {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        margin-bottom: 20px;
        margin-top: 20px;

        a.router-link-active {
            font-weight: 500;
        }
        

        .link {
            text-decoration: none;
            color: black;
            margin: 0 20px;
            font-size: 18px;
            // font-weight: 500;
        }

        .link:hover {
            color: black;
            font-weight: 500;
        }

        .link:active {
            color: rgb(81, 81, 81);
            font-weight: 500;
        }
    }

    &__sing-out-btn {
        // padding-top: 20px;
        margin: 0 20px;
        font-size: 18px;
        text-decoration: none;
        color: rgb(241, 78, 78);
    }

    .sing-out-btn:hover {
        color: rgb(229, 20, 20);
    }

    .sing-out-btn:active {
        color: rgb(177, 0, 0);
    }
}

.header {
    background-color: rgba(255, 255, 255, 0.839);
    backdrop-filter: blur(5px);
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.227);
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    padding: 5px 0;
    display: flex;
    justify-content: space-between;


    &__title {

        a {
            text-decoration: none;
            color: black;
            font-weight: 500;
            font-size: 25px;
        }
    }

    &__nav {

        a.router-link-active {
            font-weight: 500;
        }


        .link {
            text-decoration: none;
            color: black;
            margin: 0 20px;
        }

        .link:hover {
            color: black;
            font-weight: 500;
        }

        .link:active {
            color: rgb(81, 81, 81);
            font-weight: 500;
        }
    }

    .inner {
        width: 80%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;

        .more-btn {
            display: none;
            width: 35px;


            .line {
                width: 100%;
                height: 4px;
                background-color: black;
                border-radius: 2px;
            }
        }

        .sing-out-btn {
            text-decoration: none;
            color: rgb(241, 78, 78);
        }

        .sing-out-btn:hover {
            color: rgb(229, 20, 20);
        }

        .sing-out-btn:active {
            color: rgb(177, 0, 0);
        }
    }
}

@media (max-width: 600px) {
    .header {


        .inner {
            .header__nav {
                display: none;
            }

            .sing-out-btn {
                display: none;
            }

            .more-btn {

                display: flex;
                flex-direction: column;
                grid-gap: 5px;
            }
        }
    }


}
</style>
