<template>
    <div class="frame account body">
        <div v-if="getUser" class="account__title">
            <img src="@/assets/human.svg" alt="">
            <div class="account__title__name">
                <h1>{{ getUser.name }} {{ getUser.surname }}</h1>
            </div>

        </div>
        <div v-else class="account__title-none"></div>

        <div class="account__body">
            <div class="block account__body__info">
                <div class="block__title">
                    <h4>Информация</h4>
                    
                </div>
                <router-link class="edit-btn" :to="''" v-on:Click="openChangeInfoModal"><img src="@/assets/edit.svg"
                    alt=""></router-link>

                <div v-if="getUser" class="raw">
                    <p class="raw__title">Логин:</p>
                    <p v-if="getUser.login">{{ getUser.login }}</p>
                    <p v-else>Не указан</p>
                </div>
                <div class="line-hor" </div>
                    <div v-if="getUser" class="raw">
                        <p class="raw__title">Телефон:</p>
                        <p v-if="getUser.phone_number">{{ getUser.phone_number }}</p>
                        <p v-else>Не указан</p>
                    </div>
                    <div class="line-hor" </div>
                        <div v-if="getUser" class="raw">
                            <p class="raw__title">Почта:</p>
                            <p v-if="getUser.email">{{ getUser.email }}</p>
                            <p v-else>Не указана</p>
                        </div>

                    </div>

                    <div class="line">
                        <div class="block groups">
                            <h4>Группы</h4>
                            <router-link class="plus-btn" :to="''" v-on:Click="addGroup">+</router-link>
                            <div class="groups__group" v-if="getUser" v-for="group in getUser.group_list">
                                <p>{{ group.name_group }}</p>
                                <ButtonText :msg="'Перейти'" :path="`/groups/${group.id}`"></ButtonText>
                            </div>

                        </div>

                        <div class="block">
                            <h4>Действия</h4>
                            <div class="buttons">
                                <button-gray :msg="'Настройки'" :path="''"
                                    @touch="openChangePasswordModal"></button-gray>
                                <button-gray :msg="'Сменить пароль'" :path="''"
                                    @touch="openChangePasswordModal"></button-gray>
                                <button-red :msg="'Выйти'" :path="''" @touch="singOut"></button-red>
                            </div>

                        </div>
                    </div>


                </div>

                <modal v-if="isChangePasswordModal" :nameModal="'Смена пороля'" @closePopup="closeChangePasswordModal">
                    <form class="change" action="">
                        <input type="text" placeholder="Логин" v-model="changePassStr.login">
                        <input type="password" placeholder="Прошлый пароль" v-model="changePassStr.oldPassword">
                        <input type="password" placeholder="Новый пароль" v-model="changePassStr.password">
                        <input type="password" placeholder="Подтверждение пароля"
                            v-model="changePassStr.confirmPassword">
                        <button-gray :msg="'Сменить'" :path="''" @touch="changePassword"></button-gray>
                        <!-- <p v-if="errorAdd">{{ errorAdd }}</p> -->
                    </form>
                </modal>

                <modal v-if="isChangeInfoModal" :nameModal="'Изменить информайцию'" @closePopup="closeChangeInfoModal">
                    <form action="">
                        <input type="text" placeholder="Имя" v-model="changeInfoStr.name">
                        <input type="text" placeholder="Фамилия" v-model="changeInfoStr.surname">
                        <!-- <input type="text" placeholder="Телефон" v-model="changeInfoStr.phoneNumber">
                        <input type="text" placeholder="Почта" v-model="changeInfoStr.email"> -->
                        <button-gray :msg="'Сохранить'" :path="''" @touch="changeInfo"></button-gray>
                    </form>

                </modal>
            </div>
</template>

<script>
import ButtonGray from '@/components/utils/ButtonGray.vue';
import ButtonRed from '@/components/utils/ByttonRed.vue';
import Modal from '@/components/modal/Modal.vue';
import { singOut } from "@/helpers/utils";
import { mapActions, mapGetters } from 'vuex';
import ButtonText from '@/components/utils/ButtonText.vue';


export default {
    name: 'ProfileView',

    data() {
        return {
            user: null,
            isChangeInfoModal: false,
            isChangePasswordModal: false,
            changeInfoStr: {
                name: "",
                surname: "",
                phoneNumber: "",
                email: ""
            },
            changePassStr: {
                login: "",
                oldPassword: "",
                password: "",
                confirmPassword: ""
            },
        }
    },

    computed: {
        getUser() {
            return this.user
        }
    },

    components: {
        ButtonGray,
        ButtonRed,
        ButtonText,
        Modal
    },

    mounted() {
        this.feathProfile();
    },

    methods: {

        async feathProfile() {

            await this.download_user()
            this.user = this.get_user()

        },

        addGroup() {
            console.log('addGroup')
            this.$router.push('groups/add')
        },

        singOut() {
            this.sing_out()
        },

        openChangePasswordModal() {
            this.isChangePasswordModal = true;
        },

        closeChangePasswordModal() {
            this.isChangePasswordModal = false;
        },

        async changePassword() {

            if (this.changePassStr.login == "" || this.changePassStr.oldPassword == "" || this.changePassStr.password == "" || this.changePassStr.confirmPassword == "") {
                return;
            }
            console.log(this.changePassStr)
            let data = {
                "login": this.changePassStr.login.trimEnd(),
                "old_password": this.changePassStr.oldPassword.trimEnd(),
                "password": this.changePassStr.password.trimEnd(),
                "confirm_password": this.changePassStr.confirmPassword.trimEnd()
            };
            await this.change_user_password(data)
            this.closeChangePasswordModal()

        },

        openChangeInfoModal() {
            this.isChangeInfoModal = true
        },

        closeChangeInfoModal() {
            this.isChangeInfoModal = false
        },

        async changeInfo() {
            let data = {
                "name": this.changeInfoStr.name.trimEnd(),
                "surname": this.changeInfoStr.surname.trimEnd(),
                // "email": this.changeInfoStr.email.trimEnd(),
                // "phone_number": this.changeInfoStr.phoneNumber.trimEnd()
            };

            await this.change_user_info(data)
            await this.feathProfile();
            this.closeChangeInfoModal();
        },

        ...mapActions({
            download_user: 'profile/download_user',
            sing_out: 'auth/sing_out',
            change_user_info: 'profile/change_user_info',
            change_user_password: 'profile/change_user_password'
        }),

        ...mapGetters({
            get_user: 'profile/get_user'
        })

    }
}
</script>


<style lang="scss">
.account {

    form {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        width: 70%;
        margin: auto;

        input {
            border: none;
            border-radius: 5px;
            background-color: rgb(187, 187, 187);
            padding: 10px;
        }
    }

    &__title {
        display: flex;
        justify-content: center;

        &__name {
            width: 37%;
        }

        .raw {
            display: flex;
        }
    }

    &__body {
        

        .raw {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        

        &__info {
            position: relative;

            .edit-btn {
                position: absolute;
                margin-top: 15px;
                margin-right: 25px;
                top: 0;
                right: 0;
                width: fit-content;
            }

        }
        
        .groups {
            position: relative;

            &__group {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .plus-btn {
                position: absolute;
                margin-top: 11px;
                margin-right: 30px;
                top: 0;
                right: 0;
                width: fit-content;
                text-decoration: none;
                font-weight: 600;
                font-size: 30px;
                color: rgb(165, 165, 165);
            }

            .plus-btn:hover {
                color: rgb(131, 131, 131);
            }
        }

        .buttons {
            display: flex;
            flex-direction: column;
            grid-gap: 20px;
        }
    }
}
</style>