<template>
    <div class="frame add-device">
        <h1>Добавить датчик</h1>
        <div class="line">
            <div class="block add-device__main">
                <h4>Остновное</h4>
                <form action="">
                    <input type="text" placeholder="Серийный номер" v-model="serialNumber"
                        :class="{ 'no-valid': noValids.serialNumber }">
                    <input type="text" placeholder="Имя датчика" v-model="deviceName"
                        :class="{ 'no-valid': noValids.deviceName }">
                    <button-gray :msg="'Создать'" :path="''" @touch="addDevice"></button-gray>
                </form>
            </div>

            <div class="block add-device__groups">
                <h4 :class="{ 'no-valid': noValids.group }">Выберете группу</h4>
                <div class="add-device__groups__raw" v-if="getAcceptedGroup">
                    <p>{{ getAcceptedGroup.name_group }}</p>
                    <button-gray :msg="'Отвязать'" :path="''" @touch="unlinckGroup()"></button-gray>
                </div>

                <div v-if="getAcceptedGroup" class="line-hor"></div>

                <div class="add-device__groups__raw" v-if="getGropList" v-for="group in getGropList">
                    <p>{{ group.name_group }}</p>
                    <button-gray :msg="'Привязать'" :path="''" @touch="linckGroup(group)"></button-gray>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ButtonGray from '@/components/utils/ButtonGray.vue';

export default {
    name: 'DevicesAddView',

    data() {
        return {
            groupList: null,
            serialNumber: null,
            deviceName: null,
            acceptedGroup: null,
            noValids: {
                serialNumber: false,
                deviceName: false,
                group: false
            }
        }
    },

    computed: {
        getGropList() {
            return this.groupList
        },

        getAcceptedGroup() {
            return this.acceptedGroup
        }
    },

    components: {
        ButtonGray
    },

    mounted() {
        this.feathGroups();
    },

    methods: {

        async feathGroups() {
            await this.download_group_list();
            this.groupList = this.get_group_list()
        },

        addDevice() {
            if (this.serialNumber == '' || this.serialNumber == null) {
                this.noValids.serialNumber = true;
                return
            }
            this.noValids.serialNumber = false;
            if (this.deviceName == '' || this.deviceName == null) {
                this.noValids.deviceName = true;
                return
            }
            this.noValids.deviceName = false;
            if (this.acceptedGroup == '' || this.acceptedGroup == null) {
                this.noValids.group = true;
                return
            }
            this.noValids.group = false;
            var data = {
                "serial_number": this.serialNumber.trimEnd(),
                "group_id": this.acceptedGroup.id,
                "name": this.deviceName.trimEnd()
            }

            this.linck_device(data)
        },

        linckGroup(group) {
            var groups = this.groupList
            var accGroup = this.acceptedGroup
            if (accGroup != null) {
                groups.push(accGroup)
                accGroup = group
                groups = groups.filter(g => g.id !== group.id)
            } else {
                accGroup = group
                groups = groups.filter(g => g.id !== group.id)
            }

            this.groupList = groups
            this.acceptedGroup = accGroup

        },

        unlinckGroup() {
            this.groupList.push(this.acceptedGroup)
            this.acceptedGroup = null
        },

        ...mapActions({
            download_group_list: 'groups/download_groupList',
            linck_device: 'devices/link_device'
        }),

        ...mapGetters({
            get_group_list: 'groups/get_groupList'
        })

    }
}
</script>


<style lang="scss">
.add-device {

    &__main {
        form {
            display: flex;
            flex-direction: column;
            grid-gap: 20px;
            width: 80%;
            margin: auto;

            input {
                border: none;
                border-radius: 5px;
                background-color: rgb(187, 187, 187);
                padding: 10px;
            }

            @media (max-width: 900px) {
                width: 100%;
            }
        }

        .no-valid {
            border: solid 1px;
            border-color: rgb(255, 119, 119);
            background-color: rgb(254, 218, 218);
        }


    }

    &__groups {
        .no-valid {
            color: rgb(255, 119, 119);
        }

        &__raw {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }
    }
}
</style>