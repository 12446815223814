<template>
    <div class="point-green" v-if="isWork"><p class="tooltip-text">{{msg}}</p></div>
    <div class="point-red" v-else><p class="tooltip-text">{{msgRed}}</p></div>
</template>

<script>
export default {
    name: 'Point',
    props: {
        isWork: Boolean,
        msg: {
            default: "Подсказка",
            type: String
        },
        msgRed: {
            default: "Подсказка",
            type: String
        }
    },

    methods: {

        touch() {
            this.$emit('touch')
        }
    }
}
</script>

<style lang="scss">
.point-green {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: green;

    .tooltip-text {
        visibility: hidden;
        position: absolute;
        bottom: 10px;
        left: 0;
        background-color: #FBEEDB;
        padding: 10px;
        border-radius: 10px;
        min-width: 200px;

        box-shadow: 0 0 5px 1px rgba(40, 40, 40, 0.081);
    }


}

.point-green:hover {

    .tooltip-text {
        visibility: visible;
        /* Вот такое чудо! */
    }
}

.point-red {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: red;

    .tooltip-text {
        visibility: hidden;
        position: absolute;
        bottom: 10px;
        left: 0;
        background-color: #FBEEDB;
        padding: 10px;
        border-radius: 10px;
        min-width: 200px;

        box-shadow: 0 0 5px 1px rgba(40, 40, 40, 0.081);
    }
}

.point-red:hover {

    .tooltip-text {
        visibility: visible;
        /* Вот такое чудо! */
    }
}
</style>
