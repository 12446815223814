<template>
    <div class="coord">
        <div v-if="equipment" class="coord__head">
            <h1>Местоположение {{ equipment.name }}</h1>
        </div>

        <div class="coord__date">
            <h4>Выбрать дату и время</h4>
            <form action="#">
                <div class="coord__date__in">
                    <p>Дата начала</p>
                    <input type="datetime-local" v-model="date_one">
                </div>

                <div class="coord__date__in">
                    <p>Дата конца</p>
                    <input type="datetime-local" v-model="date_two">
                </div>

                <button-gray :msg="'Посмотреть'" :path="''" @touch="getCoords"></button-gray>
            </form>
        </div>


        <div v-if="getCoord" class="coord__block">
            <my-map :coord="getCoord" :key="getCoord"></my-map>
        </div>
    </div>
</template>

<script>
import ButtonGray from "@/components/utils/ButtonGray.vue"
import ButtonBack from "@/components/utils/ButtonBack.vue";
import MyMap from "@/components/MyMap.vue"
import { mapGetters, mapActions } from "vuex";


export default {
    name: 'CoordView.vue',

    data() {
        return {
            equipment: null,
            coord: null,
            date_one: null,
            date_two: null,
        }
    },

    computed: {
        getEquipment() {
            return this.equipment
        },

        getCoord() {
            return this.coord
        }
    },

    components: {
        ButtonGray,
        ButtonBack,
        MyMap,
    },

    mounted() {
        this.feathEquipment();
    },

    methods: {

        async feathEquipment() {
            await this.download_eq(this.$route.params.equipmentId);
            this.equipment = this.get_eq()

            var d_two = new Date();
            var d_one = new Date(d_two.getFullYear(), d_two.getMonth(), d_two.getDate() - 1, d_two.getHours(), d_two.getMinutes(), d_two.getSeconds(), d_two.getMilliseconds());
            console.log(d_one)
            console.log(d_two)
            this.date_one = d_one;
            this.date_two = d_two;

            let data = {
                "equipmentId": this.$route.params.equipmentId,
                "dateOne": this.date_one.toISOString(),
                "dateTwo": this.date_two.toISOString(),
            }
            // console.log(data)
            await this.download_coord(data)
            this.coord = this.get_coord()
        },

        async getCoords() {

            let data = {
                "equipmentId": this.$route.params.equipmentId,
                "dateOne": new Date(this.date_one).toISOString(),
                "dateTwo": new Date(this.date_two).toISOString(),
            }
            console.log(data)
            await this.download_coord(data)
            this.coord = this.get_coord()
        },

        ...mapActions({
            download_eq: 'equipments/download_equipment',
            download_coord: 'coordinate/download_coord'
        }),

        ...mapGetters({
            get_eq: 'equipments/get_equipment',
            get_coord: 'coordinate/get_coord'
        })

    }

}
</script>


<style lang="scss">
.coord {



    &__head {
        width: 80%;
        margin: auto;
        // padding-bottom: 20px;

        .back {
            width: 60px;
            margin-top: -50px;
        }

        h1 {
            text-align: center;
        }
    }

    &__block {
        box-shadow: 0 0 2px 2px rgba(40, 40, 40, 0.163);
        border-radius: 10px;
        padding: 20px 20px;
        margin: 20px auto;
        width: 80%;
        display: flex;
        flex-direction: column;
    }

    &__date {
        box-shadow: 0 0 2px 2px rgba(40, 40, 40, 0.163);
        border-radius: 10px;
        padding: 20px 20px;
        margin: 20px auto;
        width: 80%;
        display: flex;
        flex-direction: column;
        grid-gap: 10px;

        h4 {
            text-align: center;
            margin-top: 0;
        }

        &__in {
            display: flex;
            align-items: center;
            grid-gap: 30px;

            p {
                width: 300px;
            }
        }

        form {
            display: flex;
            flex-direction: column;
            grid-gap: 20px;

            input {
                padding: 10px 0;
                border: none;
                box-shadow: 0 0 2px 2px rgba(40, 40, 40, 0.163);
                border-radius: 5px;
                width: 100%;
                height: 20px;
            }

        }
    }
}
</style>