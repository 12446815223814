<template>

    <div v-if="group()" class="group frame">
        <h1>{{ group().name }}</h1>

        <div class="group__users">
            <div class="frame-title">
                <h3>Пользователи</h3>
                <button-text :msg="'Добавить пользователя'" :path="''" @touch="openAddUserModal"></button-text>
            </div>

            <div class="columns">
                <div class="column">
                    <div class="block group__users__user" v-for="user in users.usersLeft">
                        <h4>{{ user.name }} {{ user.surname }}</h4>

                        <div class="group__users__user__raw">
                            <p class="title">Логин</p>
                            <p v-if="user.login">{{ user.login }}</p>
                            <p v-else>Без почты</p>
                        </div>

                        <div class="line-hor"></div>

                        <div class="group__users__user__raw">
                            <p class="title">Почта</p>
                            <p v-if="user.mail">{{ user.mail }}</p>
                            <p v-else>Без почты</p>
                        </div>

                        <div class="line-hor"></div>

                        <div class="group__users__user__raw">
                            <p class="title">Телефон</p>
                            <p v-if="user.phone_number">{{ user.phone_number }}</p>
                            <p v-else>Без телефона</p>
                        </div>

                        <button-text class="btn-sel-1 btn-del" :msg="'Удалить'" :path="''"
                            @touch="openDelUserModal(user.login, group().id)"></button-text>
                        <img class="del-img" src="@/assets/delete.svg" alt="del"
                            @click="openDelUserModal(user.login, group().id)">
                        <!-- <button-text class="btn-sel-2 btn-del" :msg="'–'" :path="''"
                            @touch="openDelUserModal(user.login, group().id)"></button-text> -->
                    </div>
                </div>

                <div class="column">
                    <div class="block group__users__user" v-for="user in users.usersRight">
                        <h4>{{ user.name }} {{ user.surname }}</h4>

                        <div class="group__users__user__raw">
                            <p class="title">Логин</p>
                            <p v-if="user.login">{{ user.login }}</p>
                            <p v-else>Без почты</p>
                        </div>

                        <div class="line-hor"></div>

                        <div class="group__users__user__raw">
                            <p class="title">Почта</p>
                            <p v-if="user.mail">{{ user.mail }}</p>
                            <p v-else>Без почты</p>
                        </div>

                        <div class="line-hor"></div>

                        <div class="group__users__user__raw">
                            <p class="title">Телефон</p>
                            <p v-if="user.phone_number">{{ user.phone_number }}</p>
                            <p v-else>Без телефона</p>
                        </div>

                        <button-text class="btn-sel-1 btn-del" :msg="'Удалить'" :path="''"
                            @touch="openDelUserModal(user.login, group().id)"></button-text>
                        <img class="del-img" src="@/assets/delete.svg" alt="del"
                            @click="openDelUserModal(user.login, group().id)">
                        <!-- <button-text class="btn-sel-2 btn-del" :msg="'–'" :path="''"
                            @touch="openDelUserModal(user.login, group().id)"></button-text> -->
                    </div>
                </div>

            </div>

        </div>

        <div class="group__equipments">
            <div class="frame-title">
                <h3>Оборудование</h3>
                <button-text :msg="'Добавить оборудование'" :path="'/equipments/add'"></button-text>
            </div>
            <div class="block group__equipments__equipment" v-if="group().equipments.length != 0"
                v-for="equipment in group().equipments">
                <div class="group__equipments__equipment__title">
                    <h4>{{ equipment.name }}</h4>
                    <p>{{ equipment.serial_number }}</p>
                </div>

                <button-text class="btn-sel-1 btn-del" :msg="'Удалить'" :path="''"
                    @touch="openDelEquipmentModal(equipment.id)"></button-text>
                <img class="del-img" src="@/assets/delete.svg" alt="del" @click="openDelEquipmentModal(equipment.id)">

                <accept-del-modal v-if="isDelEquipmentModal" :nameModal="'Удаление оборудования'"
                    :text="`Вы действительно хотите удалить оборудование ${equipment.name} с серийным номером ${equipment.serial_number}?`"
                    @closePopup="closeDelEquipmentModal" @accept="deleteEquipment"></accept-del-modal>

            </div>
            <div v-else class="block">
                <h4>Пусто</h4>
            </div>
        </div>

        <div class="group__devices">
            <div class="frame-title">
                <h3>Устройства</h3>
                <button-text :msg="'Добавить устройсво'" :path="'/devices/add'"></button-text>
            </div>
            <div class="block group__devices__device" v-if="group().devices.length != 0"
                v-for="device in group().devices">
                <h4>{{ device.name }}</h4>

                <button-text class="btn-sel-1 btn-del" :msg="'Удалить'" :path="''"
                    @touch="openDelDeviceModal(device.id)"></button-text>
                <img class="del-img" src="@/assets/delete.svg" alt="del" @click="openDelDeviceModal(device.id)">

                <accept-del-modal v-if="isDelDeviceModal" :nameModal="'Удаление устройства'"
                    :text="`Вы действительно хотите удалить устройство ${device.name}?`"
                    @closePopup="closeDelDeviceModal" @accept="deleteDevice"></accept-del-modal>
            </div>
            <div v-else class="block">
                <h4>Пусто</h4>
            </div>
        </div>
        <!-- <p>{{group()}}</p> -->



        <modal v-if="isDelUserModal" :nameModal="'Удаление пользователя'" @closePopup="closeDelUserModal">
            <div class="group__del-user">
                <p>Вы действительно хотите удалить пользователя {{ delUser.login }}?</p>
                <div class="buttons">
                    <button-gray class="btn-modal-1" :msg="'Оставить'" :path="''"
                        @touch="closeDelUserModal"></button-gray>
                    <ByttonRed class="btn-modal-2" :msg="'Улалить'" :path="''" @touch="deleteUser"></ByttonRed>
                </div>
            </div>
        </modal>

        <modal v-if="isAddUserModal" :nameModal="'Добавить пользователя'" @closePopup="closeAddUserModal">
            <div class="group__add-user">
                <input type="text" v-model="addUserLogin">
                <button-gray class="btn-modal-1" :msg="'Добавить'" :path="''" @touch="addUser"></button-gray>

                <div class="group__add-user__list">
                    <div class="group__add-user__list__item" v-if="userList()" v-for="user in userList()">
                        <p>{{ user.name }}</p>
                        <button-text :msg="'Добавить'" :path="''" @touch="addUserByResent(user.login)"></button-text>
                    </div>
                </div>

            </div>
            <!-- <div class="line-hor"></div> -->

        </modal>
    </div>

</template>

<script>
import AcceptDelModal from '@/components/modal/AcceptDelModal.vue';
import Modal from '@/components/modal/Modal.vue';
import ButtonArrow from '@/components/utils/ButtonArrow.vue';
import ButtonGray from '@/components/utils/ButtonGray.vue';
import ButtonText from '@/components/utils/ButtonText.vue';
import ByttonRed from '@/components/utils/ByttonRed.vue';
import { separateCarts } from '@/helpers/utils';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'GroupView',

    data() {
        return {
            delUser: {
                login: null,
                groupId: null
            },
            delEquip: null,
            delDevice: null,
            addUserLogin: null,
            isDelUserModal: false,
            isDelDeviceModal: false,
            isDelEquipmentModal: false,
            isAddUserModal: false,
            users: {
                usersLeft: null,
                usersRight: null
            }
        }
    },

    props: {

    },

    components: {
        ButtonText,
        ButtonArrow,
        ByttonRed,
        ButtonGray,
        AcceptDelModal,
        Modal
    },

    mounted() {
        this.fetchGroup()
    },

    methods: {

        async fetchGroup() {
            await this.download_group(this.$route.params.groupId)
            let us = separateCarts(this.group().users)
            this.users.usersLeft = us.dataLeft
            this.users.usersRight = us.dataRight
        },

        async deleteUser() {
            var data = {
                "login": this.delUser.login,
                "group_id": this.delUser.groupId
            }
            console.log(data)

            await this.delete_user(data)
            await this.fetchGroup()
            this.closeDelUserModal()
        },

        openDelDeviceModal(id) {
            this.delDevice = id
            this.isDelDeviceModal = true
        },

        closeDelDeviceModal() {
            this.isDelDeviceModal = false
        },

        async deleteDevice() {
            await this.delete_device(this.delDevice)
            this.closeDelDeviceModal()
        },

        openDelEquipmentModal(id) {
            this.delEquip = id
            this.isDelEquipmentModal = true
        },

        closeDelEquipmentModal() {
            this.isDelEquipmentModal = false
        },

        async deleteEquipment() {
            await this.delete_equipment(this.delEquip)
            this.closeDelEquipmentModal()
        },

        openDelUserModal(login, groupId) {
            this.delUser.login = login;
            this.delUser.groupId = groupId;
            this.isDelUserModal = true
        },

        closeDelUserModal() {
            this.isDelUserModal = false
        },

        async addUserByResent(login) {

            var data = {
                "user_login": login,
                "group_id": this.$route.params.groupId
            }

            await this.add_user(data)
            await this.fetchGroup()
            this.closeAddUserModal()
        },

        async addUser() {
            if (this.addUserLogin != null) {
                await this.search_user(this.addUserLogin.trimEnd())

                var data = {
                    "user_login": this.get_searchUser().login,
                    "group_id": this.$route.params.groupId
                }

                await this.add_user(data)
                await this.fetchGroup()
                this.closeAddUserModal()
            }
        },

        async openAddUserModal() {
            await this.download_userList()
            this.isAddUserModal = true
        },

        closeAddUserModal() {
            this.isAddUserModal = false
        },

        ...mapActions({
            download_group: 'groups/download_group',
            download_userList: 'groups/download_userList',
            delete_user: 'groups/delete_user_as_group',
            add_user: 'groups/add_user_in_group',
            search_user: 'groups/search_user',
            delete_device: 'devices/delete_device',
            delete_equipment: 'equipments/delete_equipment'
        }),

        ...mapGetters({
            group: 'groups/get_group',
            userList: 'groups/get_userList',
            get_searchUser: 'groups/get_searchUser'
        })
    }
}
</script>

<style lang="scss">
.group {

    .btn-sel-1 {
        position: absolute;
        top: 10;
        margin-right: 20px;
        right: 0;
    }

    .del-img {
        position: absolute;
        top: 10;
        margin-right: 20px;
        right: 0;
        width: 20px;
        height: 20px;
    }

    .btn-sel-2 {
        position: absolute;
        top: 10;
        margin-right: 20px;
        right: 0;

        font-weight: 900
    }

    &__del-user {
        display: flex;
        flex-direction: column;

        .buttons {
            display: flex;
            justify-content: space-between;

            .btn-modal-1 {
                width: 40%;
            }

            .btn-modal-2 {
                width: 40%;
            }
        }
    }

    &__add-user {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        width: 80%;

        @media (max-width: 600px) {
            width: 100%;
        }

        input {
            border: none;
            border-radius: 5px;
            background-color: rgb(187, 187, 187);
            padding: 10px;
        }

        &__list {

            &__item {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    &__devices {

        &__device {
            position: relative;
        }

    }

    &__users {
        margin-top: 10px;

        &__user {
            position: relative;

            &__raw {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    &__equipments {

        &__equipment {
            position: relative;

            &__title {

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                h4 {
                    margin-bottom: 5px;
                }

                p {
                    margin: 0;
                    font-size: 14px;
                    color: rgb(121, 121, 121);
                }
            }
        }
    }

}
</style>