<template>
  <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
  <HintUI v-if="$store.state.hint" :data="$store.state.hint"></HintUI>
  <header-main v-if="auth"></header-main>
  <router-view class="body" />
</template>

<script>
import HeaderMain from './components/main/HeaderMain.vue';
import { mapGetters } from 'vuex';
import HintUI from './components/utils/HintUI.vue';

export default {
  name: 'StartView',

  components: {
    HeaderMain,
    HintUI
  },

  computed: {
    ...mapGetters({
      auth: 'auth/get_authToken',
      hint: 'get_hint'
    })
  },

  methods: {

  },

}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

#app {
  font-family: "Roboto", sans-serif;
}

.grecaptcha-badge {
  display: none;
}

.body {
  margin-top: 70px;
}

.btn-del {
  color: #f14e4e;
}

.btn-del:hover {
  color: rgb(226, 8, 8);
}

.line-hor {
  width: 90%;
  border-radius: 1px;
  height: 2px;
  margin: auto;
  background-color: rgb(238, 238, 238);
}

.frame {

  .frame-title {
    display: flex;
    justify-content: space-between;
    width: 80%;
    align-items: center;
    margin: auto;

    h3 {
      width: fit-content;
      margin: 0;
    }
  }

  h1 {
    width: 80%;
    margin: auto;
    text-align: center;
  }

  h3 {
    width: 80%;
    margin: auto;
  }

  .btn-sel-1 {
    display: block;

    @media (max-width: 650px) {
      display: none;
    }
  }

  .btn-sel-2 {
    display: none;

    @media (max-width: 650px) {
      display: block;
    }
  }

  .del-img {
    display: none;

    @media (max-width: 650px) {
      display: block;
    }
  }

  .line {
    width: 89%;
    margin: auto;
    display: flex;
    justify-content: space-between;

    @media (max-width: 900px) {
      flex-direction: column;
      width: 100%;
    }

    .block {
      width: 40%;

      @media (max-width: 900px) {
        width: 80%;
        margin: 20px auto;
      }
    }
  }

  .columns {
    display: flex;
    width: 89%;
    margin: auto;
    justify-content: space-between;

    .column {
      width: 60%;
    }

    @media (max-width: 900px) {
      flex-direction: column;
      width: 100%;
      .column {
        margin: auto;
        width: 100%;
      }
    }
  }

  .block {
    box-shadow: 0 0 2px 2px rgba(40, 40, 40, 0.163);
    border-radius: 10px;
    padding: 20px 20px;
    margin: 20px auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    animation-name: block;
    animation-duration: 0.2s;
    animation-fill-mode: initial;


    @keyframes block {
      0% {
        opacity: 0%
      }

      100% {
        opacity: 100%
      }

    }

    h4 {
      text-align: center;
      margin-top: 0;
    }
  }

  .block-black {
    box-shadow: 0 0 2px 2px rgba(40, 40, 40, 0.163);
    background-color: #343434;
    border-radius: 10px;
    padding: 20px 20px;
    margin: 20px auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    animation-name: block;
    animation-duration: 0.2s;
    animation-fill-mode: initial;


    @keyframes block {
      0% {
        opacity: 0%
      }

      100% {
        opacity: 100%
      }

    }

    h4 {
      text-align: center;
      margin-top: 0;
      color: #F1F1F1;
    }

    p {
      color: #F1F1F1;
    }
  }
}

body {
  margin: 0;
}
</style>
