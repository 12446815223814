import { singOut } from '@/helpers/utils';
import Cookies from 'js-cookie';
import api from '@/helpers/api';
import router from '@/router';

export const Equipments = {
    state: () => ({
        equipments: null,
        equipment: null,
        hiddenEquip: null
    }),
    getters: {
        get_equipments(state) {
            return state.equipments

        },

        get_equipment(state) {
            return state.equipment

        },

        get_hiddenEquip(state) {
            return state.hiddenEquip

        },
    },
    mutations: {
        set_equipments(state, equipments) {
            state.equipments = equipments
        },

        set_equipment(state, equipment) {
            state.equipment = equipment
        },

        set_hiddenEquip(state, hiddenEquip) {
            state.hiddenEquip = hiddenEquip
        },
    },
    actions: {

        async download_equipments({ commit, state }) {
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('authToken')
                    }
                }

                const res = await api.get('/equipments', config)
                var equipments = res.data
                for (const i in equipments) {
                    equipments[i]['hidden'] = true
                }
                // console.log(res.data)
                commit('set_equipments', res.data)
            } catch (error) {
                if (error.response.status == 401) {
                    singOut();
                }
                // console.log(error);
            }

        },

        async download_equipment({ commit, state }, id) {
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('authToken')
                    }
                }

                const res = await api.get('/equipments/' + id, config)

                commit('set_equipment', res.data)
            } catch (error) {
                if (error.response.status == 401) {
                    singOut();
                }
                // console.log(error);
            }

        },

        async save_equipment({ commit, state }, data) {
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('authToken')
                    }
                }

                const res = await api.post('/equipments/add', data, config)
                commit('set_hint', res.data || 'Действие', { root: true })
                router.back()
                // commit('set_equipments', res.data)
            } catch (error) {
                if (error.response.status == 401) {
                    singOut();
                }
                // console.log(error);
            }

        },

        async link_equipment_to_device({ commit, state }, data) {
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('authToken')
                    }
                }

                const res = await api.post('/equipments/link/device', data, config)
                commit('set_hint', 'Вы успешно привязали устройство', { root: true })
                commit('set_equipment', res.data)
                // router.back()
                // commit('set_equipments', res.data)
            } catch (error) {
                if (error.response.status == 401) {
                    singOut();
                }
                commit('set_hint', error.response.data.massage || 'Ошибка', { root: true })
                // console.log(error);
            }

        },

        async link_equipment_to_group({ commit, state }, data) {
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('authToken')
                    }
                }

                const res = await api.post('/groups/link/equipment', data, config)
                commit('set_hint', 'Вы успешно привязали оборудование к другой группе', { root: true })
            } catch (error) {
                if (error.response.status == 401) {
                    singOut();
                }
                commit('set_hint', error.response.data.massage || 'Ошибка', { root: true })
                // console.log(error);
            }

        },

        async delete_equipment({ commit, state }, id) {
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('authToken')
                    }
                }

                const res = await api.post('/equipments/delete?equipment_id=' + id, {}, config)
                commit('set_hint', 'Удаление оборудование прошло успешно', { root: true })
                router.back()
                // commit('set_equipments', res.data)
            } catch (error) {
                if (error.response.status == 401) {
                    singOut();
                }
                // console.log(error);
            }

        },

        hide_equip({ commit, state }, data) {
            state.hiddenEquip.push(data)
        }
    },

    namespaced: true
}