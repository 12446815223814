import { singOut } from '@/helpers/utils';
import Cookies from 'js-cookie';
import api from '@/helpers/api';

export const Profile = {
    state: () => ({
        user: null,
    }),
    getters: {
        get_user(state) {
            return state.user
        },
    },
    mutations: {
        set_user(state, user) {
            state.user = user
        },
    },
    actions: {

        async download_user({ commit, state }) {
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('authToken')
                    }
                }

                const res = await api.get('/users/info', config)
                // console.log(res);
                commit('set_user', res.data)
            } catch (error) {
                if (error.response.status == 401) {
                    singOut();
                }
                // console.log(error);
            }
            
        },

        async change_user_info({ commit, state }, data) {
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('authToken')
                    }
                }

                const res = await api.post('/users/info/change', data, config)
                // console.log(res);
                commit('set_hint', 'Вы успешно поменяли информацию в профиле', { root: true })
                commit('set_user', res.data)
            } catch (error) {
                if (error.response.status == 401) {
                    singOut();
                }
                // console.log(error);
            }
            
        },

        async change_user_password({ commit, state }, data) {
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('authToken')
                    }
                }

                const res = await api.post('/users/password/change', data, config)
                // console.log(res);
                commit('set_hint', 'Вы успешно сменили пароль', { root: true })
                commit('set_user', res.data)
            } catch (error) {
                if (error.response.status == 401) {
                    singOut();
                }
                commit('set_hint', error.response.data.massage || 'Ошибка', { root: true })
                // console.log(error);
            }
            
        },
    },

    namespaced: true
}