import { singOut } from '@/helpers/utils';
import Cookies from 'js-cookie';
import api from '@/helpers/api';
import router from '@/router';

export const Groups = {
    state: () => ({
        userList: null,
        searchUser: null,
        groupList: null,
        group: null
    }),
    getters: {
        get_userList(state) {
            return state.userList
        },

        get_searchUser(state) {
            return state.searchUser
        },

        get_groupList(state) {
            return state.groupList
        },

        get_group(state) {
            return state.group
        },
    },
    mutations: {
        set_userList(state, userList) {
            state.userList = userList
        },

        set_searchUser(state, searchUser) {
            state.searchUser = searchUser
        },

        set_groupList(state, groupList) {
            state.groupList = groupList
        },

        set_group(state, group) {
            state.group = group
        },
    },
    actions: {

        async create_group({ commit, state }, data) {
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('authToken')
                    }
                }

                const res = await api.post('/groups/add', data, config)
                // console.log(res);
                commit('set_hint', res.data || 'Действие', { root: true })
                // commit('set_searchUser', res.data)
                router.back()
            } catch (error) {
                if (error.response.status == 401) {
                    singOut();
                }
                // console.log(error);
                commit('set_hint', error.response.data.massage || 'Ошибка', { root: true })
            }
            
        },

        async download_userList({ commit, state }) {
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('authToken')
                    }
                }

                const res = await api.get('/users/assigned', config)
                // console.log(res);
                commit('set_userList', res.data)
            } catch (error) {
                if (error.response.status == 401) {
                    singOut();
                }
                // console.log(error);
            }
            
        },

        async download_groupList({ commit, state }) {
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('authToken')
                    }
                }

                const res = await api.get('/groups?is_list=true', config)
                // console.log(res);
                commit('set_groupList', res.data)
            } catch (error) {
                if (error.response.status == 401) {
                    singOut();
                }
                // console.log(error);
            }
            
        },

        async download_group({ commit, state }, groupId) {
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('authToken')
                    }
                }

                const res = await api.get('/groups/' + groupId, config)
                // console.log(res);
                commit('set_group', res.data)
            } catch (error) {
                if (error.response.status == 401) {
                    singOut();
                }
                // console.log(error);
            }
            
        },

        async search_user({ commit, state }, userLogin) {
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('authToken')
                    }
                }

                const res = await api.get('/users?login=' + userLogin, config)
                // console.log(res);
                commit('set_searchUser', res.data)
            } catch (error) {
                if (error.response.status == 401) {
                    singOut();
                }
                // console.log(error);
                commit('set_hint', error.response.data.massage || 'Ошибка', { root: true })
            }
            
        },

        async delete_user_as_group({ commit, state }, data) {
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('authToken')
                    }
                }

                const res = await api.post('/groups/delete/user', data, config)
                // console.log(res);
                // commit('set_searchUser', res.data)
                commit('set_hint', res.data || 'Информация', { root: true })
            } catch (error) {
                if (error.response.status == 401) {
                    singOut();
                }
                // console.log(error);
                commit('set_hint', error.response.data.massage || 'Ошибка', { root: true })
            }
            
        },

        async add_user_in_group({ commit, state }, data) {
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('authToken')
                    }
                }

                const res = await api.post('/groups/link/user', data, config)
                // console.log(res);
                // commit('set_searchUser', res.data)
                commit('set_hint', res.data || 'Информация', { root: true })
            } catch (error) {
                if (error.response.status == 401) {
                    singOut();
                }
                // console.log(error);
                commit('set_hint', error.response.data.massage || 'Ошибка', { root: true })
            }
            
        },
    },

    namespaced: true
}