<template>
    <div class="sing-in">
        <div class="sing-in__logo">
            <h1><a href="/">TRAS</a></h1>
            <p>Система автоматизации проката инструмента</p>
        </div>
        <div class="sing-in__body">
            <h3>Регистрация</h3>
            <form action="#">
                <div v-if="form == 1" class="form-1">
                    <input type="text" placeholder="Имя *" v-model="name" :class="{ 'no-valid': noValids.name }">
                    <input type="text" placeholder="Логин *" v-model="login" :class="{ 'no-valid': noValids.login }">
                    <vue-tel-input 
                        v-model="phone" 
                        :autoFormat="true" 
                        mode="international"  
                        :enabledCountryCode="true" 
                        :placeholder="'Введите номер телефона'"
                        :defaultCountry="'RU'"
                        :dropdownOptions="{showDialCodeInSelection: true}"
                        @validate="valid"
                        :class="{ 'no-valid': noValids.phone }"></vue-tel-input>
                    <input type="mail" placeholder="Почта" v-model="mail">
                    <button-gray :msg="'Продолжить'" @touch="nextForm"></button-gray>
                </div>

                <div v-if="form == 2" class="form-2">
                    <button-text :msg="'< Вернуться'" :path="''" @touch="backForm"></button-text>
                    <input type="password" placeholder="Пароль *" v-model="password" :class="{ 'no-valid': noValids.password }">
                    <input type="password" placeholder="Подтверждение пароля *" v-model="confirm_password" :class="{ 'no-valid': noValids.confirm_password }">
                    <div>
                        <div class="check-box">
                            <input type="checkbox" name="Владелец проката" v-model="isOwner">
                            <p>Владелец проката</p>
                        </div>
                        <div class="check-box">
                            <input type="checkbox" name="Владелец проката" v-model="isAgreement" :class="{ 'no-valid': noValids.agreement }">
                            <p>Я принимаю условия
                                <router-link :to="'/agreement'">пользовательского соглашения</router-link>
                            </p>
                        </div>
                    </div>

                    <button-gray :text="'Зарегистрироватся'" :path="``" @touch="registration(recaptcha)"></button-gray>
                </div>


            </form>
            <p class="error" v-if="error">{{ error }}</p>
            <button-text class="back" :msg="'Назад'" :path="`/`"></button-text>
        </div>

    </div>
</template>

<script>
import ButtonGray from "@/components/utils/ButtonGray.vue"
import ButtonText from "@/components/utils/ButtonText.vue";
import { mapActions } from "vuex";
import { computed } from "vue";
import PhoneInput from "@/components/utils/PhoneInput.vue";
import { parsePhone } from "@/helpers/utils";
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'

import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

export default {
    name: 'RegistrationView',

    data() {
        return {
            name: null,
            login: null,
            password: null,
            confirm_password: null,
            phone: null,
            phoneValid: false,
            mail: null,
            isOwner: null,
            error: null,
            form: 1,
            isAgreement: false,
            noValids: {
                confirm_password: false,
                password: false,
                login: false,
                name: false,
                phone: false,
                agreement: false
            }
        }
    },

    computed: {

    },

    components: {
        ButtonGray,
        ButtonText,
        PhoneInput,
        VueTelInput
    },

    mounted() {
    },

    setup() {
        const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

        const recaptcha = async () => {
            // (optional) Wait until recaptcha has been loaded.
            await recaptchaLoaded()

            // Execute reCAPTCHA with action "login".
            const token = await executeRecaptcha('login')

            return token;
            // Do stuff with the received token.
        }

        return {
            recaptcha
        }
    },

    methods: {
        valid(v) {
            this.phoneValid = v.valid
        },

        async registration(recaptcha) {
            if (this.password == null || this.confirm_password == null) {
                this.error = "Придумайте пароль"
                this.noValids.password = true;
                this.noValids.confirm_password = true;
                return;
            }
            this.noValids.password = false;
            this.noValids.confirm_password = false;

            if (!this.isAgreement) {
                this.error = "Вы не дали согласие"
                this.noValids.agreement = true;
                return;
            }
            this.noValids.agreement = false;

            if (this.password == this.confirm_password) {

                var owner;
                if (this.isOwner) {
                    owner = "ROLE_OWNER"
                } else {
                    owner = "ROLE_MANAGER"
                }

                let data = {
                    "name": this.name.trimEnd(),
                    "phone": parsePhone(this.phone),
                    "mail": "",
                    "login": this.login.trimEnd(),
                    "password": this.password.trimEnd(),
                    "role": owner,
                    "confirm_password": this.confirm_password.trimEnd(),
                    "site_token": await recaptcha()
                };

                if (this.mail != null) {
                    data.mail = this.mail.trimEnd()
                }
                this.registration_stor(data)
            } else {
                this.error = "Пароли не совпадают"
                this.noValids.confirm_password = true;
            }
        },

        nextForm() {
            if (this.name == null) {
                this.error = "Заполните все обязательные поля"
                this.noValids.name = true;
                return;
            }
            this.noValids.name = false;

            if (this.login == null) {
                this.error = "Заполните все обязательные поля"
                this.noValids.login = true;
                return;
            }
            this.noValids.login = false;

            if (this.phone == null || !this.phoneValid) {
                this.error = "Заполните все обязательные поля"
                this.noValids.phone = true;
                return;
            }
            this.noValids.phone = false;

            if (this.phone[17] == '_') {
                this.error = "Заполните все обязательные поля"
                this.noValids.phone = true;
                return;
            }
            this.noValids.phone = false;

            this.form++
            this.error = null
        },

        backForm() {
            this.form--
        },

        ...mapActions({
            registration_stor: 'auth/registration'
        })
    }
}
</script>


<style lang="scss">
.sing-in {
    width: 100%;
    display: flex;
    margin-top: 20%;

    &__logo {
        width: 50%;
        text-align: center;

        h1 {
            font-size: 60px;
            font-weight: 400;

            a {
                text-decoration: none;
                color: black;
            }
        }

        p {
            width: 40%;
            margin: auto;
        }
    }

    &__body {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;

        form {
            display: flex;
            flex-direction: column;
            grid-gap: 20px;
            width: 50%;
            margin: auto;

            input {
                border: none;
                border-radius: 5px;
                background-color: rgb(187, 187, 187);
                padding: 10px;
            }

            .form-1 {
                display: flex;
                grid-gap: 20px;
                flex-direction: column;
            }

            .form-2 {
                display: flex;
                grid-gap: 20px;
                flex-direction: column;
            }

            .check-box {
                display: flex;
                width: 100%;
                grid-gap: 15px;

                p {
                    margin: 10px 0;
                }
            }
        }

        .back {
            padding-top: 50px;
        }

        .error {
            text-align: center;
            color: red;
        }
    }
}

@media (max-width: 990px) {

    .sing-in {
        flex-direction: column;
        margin-top: 5%;
        grid-gap: 100px;

        &__logo {
            width: 100%;

            p {
                width: 70%;
                margin: auto;
            }
        }

        &__body {
            width: 100%;
            margin: auto;

            form {
                width: 80%;
            }
        }

        .back {
            width: fit-content;
            margin: auto;
            text-align: center;
        }
    }

}
</style>