<template>
    <router-link class="btn-text" :to="path" v-on:Click="touch">
        {{ msg }}
    </router-link>
</template>

<script>
export default {
    name: 'ButtonText',

    props: {
        path: String,
        msg: String
    },

    methods: {

        touch() {
            this.$emit('touch')
        }
    }
}
</script>

<style lang="scss">
.btn-text {
    color: #8e8e8e;
    text-decoration: none;
    // margin-top: 0;
}

.btn-text:hover {
    color: #6b6b6b;
    text-decoration: none;
    // margin-top: 0;
}
</style>